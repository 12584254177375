uniform float time;
uniform vec2 resolution;
uniform vec2 mouse;
uniform int displayIndex;
uniform int previousDisplayIndex;
uniform float displayTransition;
uniform mat3 displayTransitionData;
varying vec2 vUv;

// #include "displays/colorfulworley"
// #include "displays/colormosaic"
#include "displays/fbmsmoke"
// #include "displays/tutorial"
// #include "displays/visionpro"

vec4 getColor(in int index) {
  switch (index) {
  case 0:
    return fbmsmoke(vec3(0.263, 0.416, 0.557));
    break;
  case 1:
    return fbmsmoke(vec3(0.710, 0.320, 0.120));
    break;
  case 2:
    return fbmsmoke(vec3(0.710, 0.809, 0.709));
    break;
  case 3:
    return fbmsmoke(vec3(0.839, 0.809, 0.402));
    break;
  }
}

void main() {
  mat3 d = displayTransitionData;

  vec4 color = mix(
    mix(
      mix(
        mix(getColor(int(d[0][0])), getColor(int(d[0][1])), d[0][2]),
        getColor(int(d[1][0])),
        d[1][1] // p2
      ),
      getColor(int(d[1][2])),
      d[2][0] // p3
    ),
    getColor(int(d[2][1])),
    d[2][2] // p4
  );

  gl_FragColor = color;
}
