import Time from "./Time.js";
import { Raycaster, Vector2, Camera, Object3D, Intersection } from "three";

/**
 * The Mouse information class. This class contains some information about the
 * mouse, such as its location, normalized location, and whether it is moving or
 * not. Mouse event listeners that peruse this information must be registered
 * after this class's instantiation.
 *
 * @remarks
 *
 * You can use {@link EventTarget.addEventListener} to listen to events
 * supported by the `Mouse`.
 *
 * The following {@link CustomEvent}s are supported:
 *
 * - `move`: Dispatched when the mouse has moved in the window.
 * - `stop`: Dispatched when the mouse has stopped moving in the window.
 *
 * The `detail` property of the custom event is set to the `Mouse` instance e.g.
 *
 * ```javascript
 * mouse.addEventListener("move", e => {
 *   const { coords, ndc, delta } = e.detail;
 *
 *   // ...
 * });
 * ```
 */
export default class Mouse extends EventTarget {
  /**
   * The current coordinates of the mouse, where the top left is (0, 0) and the
   * bottom right is (`width`, `height`).
   *
   * If the mouse is outside the window, this value will be `null`.
   */
  coords: Vector2 | null;
  /**
   * The normalized device coordinates (NDC) of the mouse, where the top left is
   * (-1, -1), the bottom right is (1, 1), and the center is (0, 0).
   *
   * If the mouse is outside the window, this value will be `null`.
   */
  ndc: Vector2 | null;
  /**
   * The old coordinates of the mouse, where the top left is (0, 0) and the
   * bottom right is (`width`, `height`).
   *
   * The old coordinates are the coordinates before the last `pointermove` event
   * was triggered.
   */
  oldCoords: Vector2 | null;
  /**
   * The difference between {@link Mouse.coords} and {@link Mouse.oldCoords}.
   */
  delta: Vector2 | null;
  /**
   * `true` if the mouse is moving, `false` otherwise.
   *
   * The mouse is considered to have stopped moving after a certain timeout
   * after a `pointermove` event.
   */
  moving: boolean = false;
  /**
   * The {@link THREE.Raycaster} associated with this mouse.
   */
  raycaster?: Raycaster;

  private moveTimeout?: NodeJS.Timeout;

  /**
   * The target of mouse events, such as the canvas.
   */
  readonly target: Element & EventTarget;
  /**
   * The time tracking instance.
   */
  readonly time: Time;

  /**
   * Creates a new mouse tracking instance for the specified device and target.
   *
   * @param target          The target of mouse events, such as the canvas.
   * @param time            A time tracking instance.
   * @param enableRaycaster Whether to initialize the raycaster associated with
   *                        this mouse.
   */
  constructor(
    target: Element & EventTarget,
    time: Time,
    enableRaycaster: boolean = false,
  ) {
    super();

    this.coords = null;
    this.ndc = null;
    this.oldCoords = null;
    this.delta = null;

    this.target = target;
    this.time = time;
    if (enableRaycaster) this.raycaster = new Raycaster();

    target.addEventListener("pointermove", this.update.bind(this));
    target.addEventListener("mouseleave", () => {
      this.coords = null;
      this.ndc = null;
      this.delta = null;
    });
  }

  /**
   * Sets the raycaster's origin to this mouse's NDC and returns the
   * intersections with the specified 3D object(s).
   *
   * @param camera    The camera from which the ray will originate.
   * @param objects   The object(s) to check for intersection with the ray.
   * @param recursive If `true`, intersect with objects' descendants also.
   *                  Default is `false`.
   */
  raycast(
    camera: Camera,
    objects: Object3D | Object3D[],
    recursive: boolean = false,
  ): Intersection[] {
    if (this.raycaster != null) {
      this.raycaster.setFromCamera(this.ndc ?? { x: 0, y: 0 }, camera);

      if (Array.isArray(objects)) {
        return this.raycaster.intersectObjects(objects, recursive);
      } else {
        return this.raycaster.intersectObject(objects, recursive);
      }
    } else {
      console.error("Raycaster is not enabled");
    }

    return [];
  }

  /**
   * Updates the information in this instance after the mouse has moved.
   *
   * @param event The mouse event.
   */
  private update(event: Event) {
    // Top left: 0, 0
    // Bottom right: width, height
    const x = (event as MouseEvent).clientX;
    const y = (event as MouseEvent).clientY;

    if (this.coords === null) {
      this.coords = new Vector2(x, y);
      this.ndc = new Vector2();
      this.oldCoords = new Vector2();
      this.delta = new Vector2();
    }

    // Top left: -1, -1
    // Bottom right: 1, 1
    const nx = (x / this.target.clientWidth) * 2 - 1;
    const ny = -(y / this.target.clientHeight) * 2 + 1;

    const dx = x - this.coords.x;
    const dy = y - this.coords.y;

    this.oldCoords!.copy(this.coords);
    this.coords.set(x, y);
    this.ndc!.set(nx, ny);
    this.delta!.set(dx, dy);

    this.moving = true;
    this.dispatchEvent(new CustomEvent("move", { detail: this }));

    if (this.moveTimeout !== undefined) {
      clearTimeout(this.moveTimeout);
    }

    this.moveTimeout = setTimeout(
      () => {
        this.moving = false;
        this.dispatchEvent(new CustomEvent("stop", { detail: this }));
      },
      Math.ceil(1000 / this.time.deltaMs),
    );
  }
}
