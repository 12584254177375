import {
  BufferAttribute,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Texture,
} from "three";

export default class Gallery {
  model: Object3D;
  bakeMaterial: MeshBasicMaterial;
  ledMaterial: MeshBasicMaterial;
  displays: Object3D[];

  constructor(model: Object3D, bake: Texture) {
    this.model = model;

    bake.flipY = false;

    this.bakeMaterial = new MeshBasicMaterial({
      map: bake,
    });
    this.displays = new Array(5);

    this.model.traverse((child) => {
      if (Object.hasOwn(child, "material")) {
        const childMesh = child as Mesh;
        childMesh.material = this.bakeMaterial;

        // Use second set of UVs for lightmap
        const uv2Array = childMesh.geometry.getAttribute("uv2").array;
        childMesh.geometry.setAttribute("uv", new BufferAttribute(uv2Array, 2));
      }

      if (child.name === "Display") {
        this.displays[0] = child;
      } else if (child.name === "Display001") {
        this.displays[1] = child;
      } else if (child.name === "Display002") {
        this.displays[2] = child;
      } else if (child.name === "Display003") {
        this.displays[3] = child;
      } else if (child.name === "Display004") {
        this.displays[4] = child;
      }
    });

    this.ledMaterial = new MeshBasicMaterial({
      color: 0xffffff,
    });

    [
      this.model.getObjectByName("Foot_LED"),
      this.model.getObjectByName("Foot_LED001"),
      this.model.getObjectByName("Foot_LED002"),
      this.model.getObjectByName("Foot_LED003"),
      this.model.getObjectByName("Foot_LED004"),
      this.model.getObjectByName("Board_LED"),
      this.model.getObjectByName("Board_LED001"),
      this.model.getObjectByName("Board_LED002"),
      this.model.getObjectByName("Board_LED003"),
      this.model.getObjectByName("Board_LED004"),
    ].forEach((led) => {
      (led as Mesh).material = this.ledMaterial;
    });
  }
}
