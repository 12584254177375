import { Intersection } from "three";
import PortfolioExperience from "../../PortfolioExperience.js";
import { PortfolioExperienceOptions, homePageOptions } from "../options.js";

let lastMoveTo: number;
let wheelCameraCallback: EventListenerOrEventListenerObject;
let touchCameraCallback: EventListenerOrEventListenerObject;
let resetTouchCallback: EventListenerOrEventListenerObject;
let projectClickCallback: EventListenerOrEventListenerObject;
let displayIntersect: Intersection;

export const workPageOptions: PortfolioExperienceOptions = {
  debugCamera: {
    fov: 35,
    near: 0.1,
    far: 100,
  },
  firstPersonCamera: {
    fov: 60,
    near: 1,
    far: 20,
  },
  cameraCrane: {
    focus: false,
    // other options will be set in setup()
  },
  boxBlurPass: {
    size: 3,
    separation: 0.3,
  },
  unrealBloomPass: {
    enabled: true,
    strength: 0.2,
    radius: 1,
    threshold: 0.97,
  },
  filmicPass: {
    lensDistortion: 0.02,
    cubeDistortion: 0.02,
    chromaticDispersion: 0.012,
    scale: 0.98,
    noiseAmount: 0.02,
    near: 0,
    far: -25,
    blurSize: 5,
    blurSeparation: 1,
  },
  setup: function (experience: PortfolioExperience): void {
    const {
      canvas,
      stage,
      gallery,
      galleryCameraPathPortrait,
      galleryCameraPathLandscape,
      debugCamera,
      cameraCrane,
      device,
    } = experience;

    const isPortrait = device.viewportOrientation === "portrait";

    stage!.model.visible = false;
    gallery!.model.visible = true;
    galleryCameraPathPortrait.helper.visible = isPortrait;
    galleryCameraPathLandscape.helper.visible = !isPortrait;

    debugCamera?.position.set(0, 1.92, 20);
    cameraCrane.gimbal.position.set(0, 1.9212, 5);
    cameraCrane.gimbal.rotation.set(0, 0, 0);
    cameraCrane.moveProgress = 0;

    const path = isPortrait
      ? galleryCameraPathPortrait.curve
      : galleryCameraPathLandscape.curve;

    cameraCrane.applyOptions({
      path,
      tweenVars: {
        duration: 1,
        ease: "sine.out",
        onUpdate: () => {
          const tangent = path.getTangent(cameraCrane.moveProgress).negate();
          cameraCrane.gimbal.lookAt(
            cameraCrane.gimbal.position.clone().add(tangent),
          );
        },
      },
    });

    cameraCrane.move(undefined, 0);

    let lastTouch: Touch | null = null;

    wheelCameraCallback = (event: Event) => {
      const { deltaY: dy } = event as WheelEvent;

      lastMoveTo =
        (lastMoveTo ?? cameraCrane.moveProgress) + 0.01 * Math.sign(dy);

      if (lastMoveTo < 0) {
        lastMoveTo = 0;
      } else if (lastMoveTo > 1) {
        lastMoveTo = 1;
      }

      cameraCrane.move(undefined, lastMoveTo);
    };

    touchCameraCallback = (event: Event) => {
      const { touches } = event as TouchEvent;

      if (touches.length > 1) {
        return;
      }

      const touch = touches[0];
      if (lastTouch === null) {
        lastTouch = touch;
      }

      let t =
        cameraCrane.moveProgress + 0.01 * (lastTouch.screenY - touch.screenY);
      lastTouch = touch;

      if (t < 0) {
        t = 0;
      } else if (t > 1) {
        t = 1;
      }
      cameraCrane.move(undefined, t);
    };

    resetTouchCallback = (event: Event) => {
      const { touches } = event as TouchEvent;

      if (touches.length == 0) {
        lastTouch = null;
      }
    };

    projectClickCallback = (_event: Event) => {
      switch (displayIntersect.object) {
        case gallery!.displays[0]:
          lastMoveTo = 0.2;
          break;
        case gallery!.displays[1]:
          lastMoveTo = 0.45;
          break;
        case gallery!.displays[2]:
          lastMoveTo = 0.65;
          break;
        case gallery!.displays[3]:
          lastMoveTo = 0.8;
          break;
        case gallery!.displays[4]:
          lastMoveTo = 1;
          break;
      }

      cameraCrane.move(undefined, lastMoveTo, {
        duration: 1.5,
        ease: "power2.inOut",
      });
    };

    canvas.addEventListener("wheel", wheelCameraCallback);
    canvas.addEventListener("touchmove", touchCameraCallback);
    canvas.addEventListener("touchend", resetTouchCallback);
  },
  update: (experience: PortfolioExperience) => {
    const { mouse, activeCamera, firstPersonCamera, gallery } = experience;

    if (activeCamera === firstPersonCamera) {
      displayIntersect = mouse.raycast(firstPersonCamera, gallery!.displays)[0];

      if (displayIntersect != null) {
        document.body.style.cursor = "pointer";
        window.addEventListener("click", projectClickCallback);
      } else {
        document.body.style.cursor = "default";
        window.removeEventListener("click", projectClickCallback);
      }
    }
  },
  teardown: (experience: PortfolioExperience) => {
    const { canvas } = experience;

    canvas.removeEventListener("wheel", wheelCameraCallback);
    canvas.removeEventListener("touchmove", touchCameraCallback);
    canvas.removeEventListener("touchend", resetTouchCallback);
    window.removeEventListener("click", projectClickCallback);

    document.body.style.cursor = "default";
  },
};
