import { Camera, Mesh, Scene, Vector2, Vector3 } from "three";
import GUI from "lil-gui";
import gsap from "gsap";

// Cameras
import DebuggableCamera from "./cameras/DebuggableCamera.js";
import CameraCrane from "./cameras/CameraCrane.js";
import DeviceOrientationControls from "./cameras/DeviceOrientationControls.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

// Debug
import Debugable from "./interfaces/Debugable.js";

// Experience-specific
import html, { PortfolioExperienceHtml } from "./portfolio-experience/html.js";
import PagesManager from "./portfolio-experience/PagesManager.js";
import Overlay from "./shared/Overlay.js";
import sources from "./portfolio-experience/sources.js";
import {
  overlayOptions,
  homePageOptions,
  aboutPageOptions,
  workPageOptions,
  myselfPaintingOptions,
} from "./portfolio-experience/options.js";
import {
  helpersLayerId,
  monitorReflectionLayerId,
} from "./portfolio-experience/constants.js";

// Post-processing
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { FilmicPass } from "./portfolio-experience/postprocessing/FilmicPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";

// Utils
import Debug from "./utils/Debug.js";
import Device from "./utils/Device.js";
import Mouse from "./utils/Mouse.js";
import RenderManager from "./utils/RenderManager.js";
import Resources, { Sources } from "./utils/Resources.js";
import Time from "./utils/Time.js";

// World
import Floor from "./portfolio-experience/world/Floor.js";
import Monitor from "./portfolio-experience/world/Monitor.js";
import Painting from "./portfolio-experience/world/Painting.js";
import Roof from "./portfolio-experience/world/Roof.js";
import Stage from "./portfolio-experience/world/Stage.js";
import Gallery from "./portfolio-experience/world/Gallery.js";

// Curves
import {
  galleryCameraCurvePointsPortrait,
  galleryCameraCurvePointsLandscape,
} from "./portfolio-experience/curves.js";
import CatmullRomCurve3Builder from "./helpers/CatmullRomCurve3Builder.js";

import Update from "./interfaces/Update.js";
import { BoxBlurPass } from "./portfolio-experience/postprocessing/BoxBlurPass.js";

export default class PortfolioExperience implements Debugable, Update {
  canvas: HTMLCanvasElement;
  activeScene: Scene;
  loadingTimeline: gsap.core.Timeline;
  overlay: Overlay;

  // HTML
  html: PortfolioExperienceHtml;
  pagesManager: PagesManager;

  // Cameras
  activeCamera: Camera;
  cameraCrane: CameraCrane;
  firstPersonCamera: DebuggableCamera;
  debugCamera?: DebuggableCamera;
  deviceOrientationControls?: DeviceOrientationControls;
  orbitControls?: OrbitControls;

  // Post-processing
  boxBlurPass?: BoxBlurPass;
  filmicPass?: FilmicPass;
  renderPass?: RenderPass;
  unrealBloomPass?: UnrealBloomPass;
  smaaPass?: SMAAPass;

  // World
  floor?: Floor;
  roof?: Roof;
  monitor?: Monitor;
  stage?: Stage;
  myselfPainting?: Painting;
  gallery?: Gallery;

  // Utils
  debug?: Debug;
  mouse: Mouse;
  renderManager: RenderManager;
  resources: Resources;
  device: Device;
  time: Time;

  // Animations
  focusZTo?: gsap.QuickToFunc;
  galleryCameraPathPortrait: CatmullRomCurve3Builder;
  galleryCameraPathLandscape: CatmullRomCurve3Builder;

  // Callbacks
  resizeCallback: EventListenerOrEventListenerObject;
  pageReloadCallback?: EventListenerOrEventListenerObject;

  debugFolder?: GUI;

  constructor(canvas: HTMLCanvasElement) {
    // Global access
    Object.defineProperty(window, "experience", {
      value: this,
    });

    this.canvas = canvas;
    this.activeScene = new Scene();

    // Utils

    this.device = new Device();
    this.time = new Time();

    // Mouse

    this.mouse = new Mouse(this.canvas, this.time, true);

    // Resources

    this.resources = new Resources(sources as Sources);
    this.resources.load();

    // Camera & CameraCrane

    this.firstPersonCamera = new DebuggableCamera(
      window.innerWidth,
      window.innerHeight,
      homePageOptions.firstPersonCamera,
    );
    this.firstPersonCamera.name = "First person camera";

    if (this.device.isPhone || this.device.isTablet) {
      this.deviceOrientationControls = new DeviceOrientationControls(
        this.firstPersonCamera,
        this.device,
      );
    }

    this.cameraCrane = new CameraCrane({ camera: this.firstPersonCamera });
    this.activeCamera = this.cameraCrane.options.camera!;

    this.activeScene.add(this.cameraCrane.gimbal);

    // Overlay

    this.overlay = new Overlay(
      window.innerWidth,
      window.innerHeight,
      overlayOptions,
    );
    this.activeScene.add(this.overlay.mesh);

    if (this.debug == null) {
      this.activeCamera = this.firstPersonCamera;
    }

    // Loading

    this.loadingTimeline = gsap.timeline();

    this.resources.addEventListener("progress", (event: Event) => {
      const { progress, total } = (event as CustomEvent).detail;

      this.loadingTimeline.to(
        this.overlay.material.uniforms.loading,
        {
          value: progress / total,
          duration: 0.8,
          ease: "power2.inOut",
        },
        ">",
      );
    });

    // Rendering

    this.renderManager = new RenderManager(
      this.canvas,
      window.innerWidth,
      window.innerHeight,
      false,
      true,
    );

    if (this.renderManager.postProcessing) {
      this.initPostProcessing();
    }

    // Curves

    this.galleryCameraPathPortrait = new CatmullRomCurve3Builder(
      0x00ffff,
      35,
      galleryCameraCurvePointsPortrait,
    );
    this.galleryCameraPathLandscape = new CatmullRomCurve3Builder(
      0x00ffff,
      35,
      galleryCameraCurvePointsLandscape,
    );

    // Pages & HTML

    this.html = html;

    if (this.html.nav != null) {
      for (let i = 0; i < this.html.nav.children.length; i++) {
        const navItem = this.html.nav.children.item(i);

        navItem!.addEventListener("mouseover", () => {
          this.monitor!.display(i);
        });
      }
    }

    this.pagesManager = new PagesManager(this);
    this.pagesManager.addPage("home", homePageOptions, html.homeDivSpans);
    this.pagesManager.addPage("about", aboutPageOptions, html.aboutDivSpans);
    this.pagesManager.addPage("work", workPageOptions, html.workDivSpans);

    // Switch pages when click on nav items

    this.html.homeAnchor?.addEventListener(
      "click",
      this.pagesManager.switchPageTo.bind(this.pagesManager, "home"),
    );

    this.html.aboutAnchor?.addEventListener(
      "click",
      this.pagesManager.switchPageTo.bind(this.pagesManager, "about"),
    );

    this.html.workAnchor?.addEventListener(
      "click",
      this.pagesManager.switchPageTo.bind(this.pagesManager, "work"),
    );

    // Debug - Must set after the HTMLManager, the Overlay, and the
    // RenderManager have been set.

    if (window.location.hash === "#debug") {
      this.debug = new Debug({ width: 400 });
      this.setDebugFolder(this.debug.gui, "PortfolioExperience");
    }

    // World

    this.initWorld();

    // ResizeObserver seems less performant for some reason, so we are going
    // with ResizeEvent.
    this.resizeCallback = () => {
      this.renderManager.setSize(window.innerWidth, window.innerHeight);
      this.firstPersonCamera.setSize(canvas.width, canvas.height);
      this.overlay.setSize(canvas.width, canvas.height);
      this.debugCamera?.setSize(canvas.width, canvas.height);
      this.boxBlurPass?.setSize(canvas.width, canvas.height);
      this.unrealBloomPass?.setSize(canvas.width, canvas.height);
      this.smaaPass?.setSize(canvas.width, canvas.height);
      this.filmicPass?.setSize(canvas.width, canvas.height);
    };

    window.addEventListener("resize", this.resizeCallback);
  }

  private initPostProcessing() {
    this.renderPass = new RenderPass(this.activeScene, this.activeCamera);
    this.renderManager.composer!.addPass(this.renderPass);

    this.smaaPass = new SMAAPass(window.innerWidth, window.innerHeight);
    this.smaaPass.needsSwap = true;
    this.smaaPass.renderToScreen = false;
    this.renderManager.composer!.addPass(this.smaaPass);

    this.boxBlurPass = new BoxBlurPass(window.innerWidth, window.innerHeight);
    this.renderManager.composer!.addPass(this.boxBlurPass);

    this.unrealBloomPass = new UnrealBloomPass(new Vector2(256), 0.2, 1, 0.8);
    this.renderManager.composer!.addPass(this.unrealBloomPass);

    this.filmicPass = new FilmicPass(
      this.activeScene,
      this.firstPersonCamera,
      window.innerWidth,
      window.innerHeight,
      true,
      {},
    );
    this.renderManager.composer!.addPass(this.filmicPass);

    this.focusZTo = gsap.quickTo(
      this.filmicPass.filmicMaterial.uniforms.focusZ,
      "value",
      { duration: 0.7 },
    );
  }

  private initWorld() {
    this.resources.addEventListener("load", (event: Event) => {
      const { items } = (event as CustomEvent).detail;

      this.stage = new Stage(
        items["stageModel"].scene,
        items["stageBakedTexture"],
      );

      // Stage & floor

      const monitorMesh = this.stage.model.getObjectByName("Monitor") as Mesh;

      this.monitor = new Monitor(monitorMesh);
      this.floor = new Floor(
        this.stage.model.getObjectByName("First_floor") as Mesh,
        items["stageBakedTexture"],
      );
      this.roof = new Roof(
        this.stage.model.getObjectByName("Roof") as Mesh,
        items["stageBakedTexture"],
      );

      this.floor.cubeCamera.layers.set(monitorReflectionLayerId);
      this.roof.cubeCamera.layers.set(monitorReflectionLayerId);
      this.monitor.mesh.layers.enable(monitorReflectionLayerId);

      this.cameraCrane.applyOptions({
        target: new Vector3(),
      });

      // Painting

      this.myselfPainting = new Painting(
        this.stage.model.getObjectByName("Myself_painting") as Mesh,
        myselfPaintingOptions,
        this.stage.model.getObjectByName(
          "Myself_painting_desktop_caption",
        ) as Mesh,
        this.stage.model.getObjectByName(
          "Myself_painting_mobile_caption",
        ) as Mesh,
      );

      // Gallery

      this.gallery = new Gallery(
        items["galleryModel"].scene,
        items["galleryBakedTexture"],
      );

      this.start();
    });
  }

  private start() {
    this.activeScene.add(this.stage!.model);
    this.activeScene.add(this.gallery!.model);
    this.activeScene.add(this.galleryCameraPathPortrait.helper);
    this.activeScene.add(this.galleryCameraPathLandscape.helper);

    this.pagesManager.currentPage = this.pagesManager.pages["home"];
    this.pagesManager.setupPage(this.pagesManager.currentPage.name);

    if (this.debugFolder != null) {
      this.monitor!.setDebugFolder(this.debugFolder, "Monitor");
      this.floor!.setDebugFolder(this.debugFolder, "Floor");
    }

    // Orientation
    this.pageReloadCallback = this.pagesManager.setupPage.bind(
      this.pagesManager,
      this.pagesManager.currentPage.name,
    );

    this.device.addEventListener(
      "viewportorientation",
      this.pageReloadCallback,
    );

    this.time.addEventListener("tick", this.update.bind(this));
    this.cameraCrane.shake();

    this.intro();
  }

  setActiveCamera(camera: DebuggableCamera) {
    this.activeCamera = camera;

    const isDebugCamera = this.activeCamera === this.debugCamera;

    this.renderManager.postProcessing = !isDebugCamera;
    this.overlay.mesh.visible = !isDebugCamera;

    if (this.renderManager.postProcessing) {
      this.renderPass!.camera = camera;
      this.filmicPass!.camera = camera;
    }
  }

  setDebugFolder(debugGui: GUI, _name: string) {
    this.debugFolder = debugGui;
    this.debugFolder.close();

    // Device

    this.debugFolder.add(this.device, "isPhone").disable();
    this.debugFolder.add(this.device, "isTablet").disable();

    // Nav elements are visible from the start
    this.html.nav!.style.opacity = "1";

    // Debug camera & orbit controls

    this.debugCamera = new DebuggableCamera(
      window.innerWidth,
      window.innerHeight,
      homePageOptions.debugCamera,
    );
    this.debugCamera.layers.enable(helpersLayerId);
    this.debugCamera.name = "Debug camera";

    this.setActiveCamera(this.debugCamera);
    this.orbitControls = new OrbitControls(this.debugCamera, this.canvas);
    this.orbitControls.enableDamping = true;

    this.deviceOrientationControls?.setDebugFolder(
      this.debugFolder,
      "Device orientation controls",
    );

    // Disable pointer events on overlay text

    for (let i = 0; i < this.html.homeDivSpans.length; i++) {
      const span = this.html.homeDivSpans.item(i);
      span.style.pointerEvents = "none";
    }

    for (let i = 0; i < this.html.aboutDivSpans.length; i++) {
      const span = this.html.aboutDivSpans.item(i);
      span.style.pointerEvents = "none";
    }

    // Set buttons to switch cameras

    const debugButtons = {
      firstPersonCamera: () => {
        this.setActiveCamera(this.firstPersonCamera);
      },
      debugCamera: () => {
        this.setActiveCamera(this.debugCamera!);
      },
    };

    this.debugFolder.add(debugButtons, "firstPersonCamera");
    this.debugFolder.add(debugButtons, "debugCamera");

    // Debug folders

    this.firstPersonCamera.setDebugFolder(
      this.debugFolder,
      "First-person camera",
    );
    this.cameraCrane.setDebugFolder(this.debugFolder, "Camera crane");
    this.overlay.setDebugFolder(this.debugFolder, "Overlay");

    // CatmullRomCurve3Builder

    this.galleryCameraPathPortrait.setDebugFolder(
      this.debugFolder,
      "Gallery camera path portrait",
    );
    this.galleryCameraPathLandscape.setDebugFolder(
      this.debugFolder,
      "Gallery camera path andscape",
    );

    // Helpers

    this.activeScene.add(this.firstPersonCamera.helper!);
    this.firstPersonCamera.helper!.layers.set(helpersLayerId);

    this.galleryCameraPathPortrait.helper.layers.set(helpersLayerId);
    this.galleryCameraPathLandscape.helper.layers.set(helpersLayerId);

    // Post-processing debug

    const postProcessingDebugFolder = debugGui.addFolder("Renderer");

    postProcessingDebugFolder
      .add(this.renderManager, "postProcessing")
      .listen();

    // SMAA pass debug

    if (this.smaaPass != null) {
      const smaaPassDebugFolder =
        postProcessingDebugFolder.addFolder("SMAA pass");

      smaaPassDebugFolder.add(this.smaaPass, "enabled");
    }

    // Box blur pass debug

    if (this.boxBlurPass != null) {
      const boxBlurPassFolder = postProcessingDebugFolder
        .addFolder("Box blur pass")
        .onChange(() => this.boxBlurPass!.applyOptions());

      boxBlurPassFolder.add(this.boxBlurPass, "enabled");
      boxBlurPassFolder.add(this.boxBlurPass.options, "size", 1, 11, 1);
      boxBlurPassFolder.add(
        this.boxBlurPass.options,
        "separation",
        0.1,
        10,
        0.1,
      );
    }

    // Unreal bloom pass debug

    if (this.unrealBloomPass != null) {
      const unrealBloomPassDebugFolder =
        postProcessingDebugFolder.addFolder("Unreal bloom pass");

      unrealBloomPassDebugFolder.add(this.unrealBloomPass, "enabled");

      unrealBloomPassDebugFolder
        .add(this.unrealBloomPass, "strength", 0, 1, 0.01)
        .listen();
      unrealBloomPassDebugFolder
        .add(this.unrealBloomPass, "radius", 0, 5, 0.01)
        .listen();
      unrealBloomPassDebugFolder
        .add(this.unrealBloomPass, "threshold", 0, 1, 0.01)
        .listen();
    }

    // Filmic pass debug

    if (this.filmicPass != null) {
      const filmicPassFolder =
        postProcessingDebugFolder.addFolder("Filmic pass");

      filmicPassFolder.add(this.filmicPass, "enabled");
      filmicPassFolder
        .add(
          this.filmicPass.filmicMaterial.uniforms.lensDistortion,
          "value",
          -1,
          1,
          0.01,
        )
        .name("Lens distortion coefficient")
        .listen();
      filmicPassFolder
        .add(
          this.filmicPass.filmicMaterial.uniforms.cubeDistortion,
          "value",
          -1,
          1,
          0.01,
        )
        .name("Cube distortion coefficient")
        .listen();
      filmicPassFolder
        .add(
          this.filmicPass.filmicMaterial.uniforms.chromaticDispersion,
          "value",
          0,
          0.1,
          0.01,
        )
        .name("Chromatic aberration")
        .listen();
      filmicPassFolder
        .add(this.filmicPass.filmicMaterial.uniforms.scale, "value", 0, 2, 0.01)
        .name("Scale")
        .listen();
      filmicPassFolder
        .add(
          this.filmicPass.filmicMaterial.uniforms.noiseAmount,
          "value",
          0,
          1,
          0.01,
        )
        .name("Noise amount")
        .listen();
      filmicPassFolder
        .add(this.filmicPass.filmicMaterial.uniforms.focusZ, "value")
        .name("Focus Z")
        .disable()
        .listen();
      filmicPassFolder
        .add(this.filmicPass.filmicMaterial.uniforms.near, "value", -30, 0, 0.1)
        .name("Near")
        .listen();
      filmicPassFolder
        .add(this.filmicPass.filmicMaterial.uniforms.far, "value", -30, 0, 0.1)
        .name("Far")
        .listen();
      filmicPassFolder
        .add(this.filmicPass.blurMaterial.uniforms.size, "value", 1, 11, 1)
        .name("Blur size")
        .listen();
      filmicPassFolder
        .add(
          this.filmicPass.blurMaterial.uniforms.separation,
          "value",
          1,
          10,
          0.1,
        )
        .name("Blur separation")
        .listen();
      filmicPassFolder
        .add(this.filmicPass.filmicMaterial.uniforms.renderDepth, "value")
        .name("Show depth texture");
    }
  }

  intro() {
    const introTimeline = gsap.timeline();

    introTimeline
      .to(
        this.overlay.material.uniforms.otherZMix,
        {
          value: 1,
          duration: 1,
        },
        "0",
      )
      .to(
        this.overlay.material.uniforms.centerZAlpha,
        {
          value: 0,
          duration: 0.3,
        },
        ">-=0.6",
      )
      .to(
        this.overlay.material.uniforms.uvScaleMultiplier,
        {
          value: 0,
          duration: 1,
          ease: "expo.in",
        },
        ">-=0.4",
      )
      .to(
        this.html.nav,
        {
          opacity: 1,
          duration: 0.3,
        },
        ">0.3",
      )
      .set(
        this.html.nav,
        {
          pointerEvents: "auto",
        },
        ">",
      );

    this.loadingTimeline.add(introTimeline, ">");
  }

  update() {
    this.debug?.stats.begin();

    this.pagesManager.currentPage?.options.update(this);
    this.orbitControls?.update();
    this.overlay.update(this);

    const world = this.stage?.model.visible
      ? this.stage.model
      : this.gallery?.model;

    if (world != null && this.focusZTo != null) {
      const intersects = this.mouse.raycast(this.activeCamera, world, true);

      if (intersects[0]) {
        this.focusZTo(intersects[0].point.z);
      }
    }

    this.renderManager.update(this);

    this.debug?.stats.end();
  }

  dispose() {
    window.removeEventListener("resize", this.resizeCallback);
    if (this.pageReloadCallback != null) {
      this.device.removeEventListener(
        "viewportorientation",
        this.pageReloadCallback,
      );
    }

    window.cancelAnimationFrame(this.time.frameId);

    // Traverse the whole scene
    this.activeScene.traverse((child) => {
      if (child instanceof Mesh) {
        child.geometry.dispose();

        for (const key in child.material) {
          const value = child.material[key];

          if (value && typeof value.dispose === "function") {
            value.dispose();
          }
        }
      }
    });

    this.renderManager.webGlRenderer.dispose();

    if (this.debug != null) {
      this.debug.gui.destroy();
    }
  }
}
