import {
  BufferAttribute,
  CubeCamera,
  CubeReflectionMapping,
  Mesh,
  MeshPhysicalMaterial,
  Texture,
  Vector3,
  WebGLCubeRenderTarget,
} from "three";
import Debugable from "../../interfaces/Debugable.js";
import GUI from "lil-gui";
import Options from "../../interfaces/Options.js";
import PortfolioExperience from "../../PortfolioExperience.js";
import Update from "../../interfaces/Update.js";
import envmapPhysicalParsReplace from "../shaders/envmapPhysicalParsReplace.frag.glsl";
import worldposReplace from "../shaders/worldPosReplace.frag.glsl";

export type FloorOptions = {
  cubeCameraResolution?: number;
  cubeMapSize?: Vector3;
  cubeMapPosition?: Vector3;
};

export default class Floor implements Debugable, Update, Options {
  options: FloorOptions;

  cubeCamera: CubeCamera;
  mesh: Mesh;
  material: MeshPhysicalMaterial;

  debugFolder?: GUI;

  constructor(mesh: Mesh, lightMap: Texture, options?: FloorOptions) {
    this.options = {
      cubeCameraResolution: options?.cubeCameraResolution ?? 1024,
      cubeMapSize: new Vector3(50, 50, 50),
      cubeMapPosition: new Vector3(0, 0, 13.62),
    };

    this.applyOptions({
      cubeMapSize: options?.cubeMapSize,
      cubeMapPosition: options?.cubeMapPosition
    });

    // Mesh

    this.mesh = mesh;
    this.mesh.geometry.computeVertexNormals();

    // Use second set of UVs for lightmap
    const uv1Array = this.mesh.geometry.getAttribute("uv").array;
    this.mesh.geometry.setAttribute("uv2", new BufferAttribute(uv1Array, 2));

    // Material

    this.material = new MeshPhysicalMaterial({ lightMap });

    this.material.onBeforeCompile = (material) => {
      //these parameters are for the cubeCamera texture
      material.uniforms.cubeMapSize = {
        value: this.options.cubeMapSize,
      };
      material.uniforms.cubeMapPos = { value: this.options.cubeMapPosition };
      material.uniforms.flipEnvMap.value = true;

      //replace shader chunks with box projection chunks
      material.vertexShader =
        "varying vec3 vWorldPosition;\n" + material.vertexShader;

      material.vertexShader = material.vertexShader.replace(
        "#include <worldpos_vertex>",
        worldposReplace,
      );

      material.fragmentShader = material.fragmentShader.replace(
        "#include <envmap_physical_pars_fragment>",
        envmapPhysicalParsReplace,
      );
    };

    this.mesh.material = this.material;

    // Cube camera

    this.cubeCamera = new CubeCamera(
      1,
      100,
      new WebGLCubeRenderTarget(this.options.cubeCameraResolution!),
    );
    this.cubeCamera.renderTarget.texture.mapping = CubeReflectionMapping;

    this.material.envMap = this.cubeCamera.renderTarget.texture;

    this.applyOptions(options);
  }

  applyOptions(options?: FloorOptions) {
    if (options?.cubeCameraResolution !== this.options.cubeCameraResolution) {
      console.warn(
        "Changing cubeCameraResolution via applyOptions is unsupported",
      );
    }

    if (options?.cubeMapSize !== undefined) {
      this.options.cubeMapSize!.copy(options.cubeMapSize);
    }
    if (options?.cubeMapPosition !== undefined) {
      this.options.cubeMapPosition!.copy(options.cubeMapPosition);
    }
  }

  setDebugFolder(debugGui: GUI, name: string) {
    this.debugFolder = debugGui.addFolder(name);

    this.debugFolder
      .add(this.options, "cubeCameraResolution", [256, 512, 1024, 2048, 4096])
      .onChange((value: number) => {
        this.cubeCamera.renderTarget.dispose();

        this.cubeCamera.renderTarget = new WebGLCubeRenderTarget(value);
        this.material.envMap = this.cubeCamera.renderTarget.texture;
      });

    this.debugFolder
      .add(this.cubeCamera.position, "x", -100, 100, 0.01)
      .name("cubeCameraPosX");
    this.debugFolder
      .add(this.cubeCamera.position, "y", -100, 100, 0.01)
      .name("cubeCameraPosY");
    this.debugFolder
      .add(this.cubeCamera.position, "z", -100, 100, 0.01)
      .name("cubeCameraPosZ");

    this.debugFolder
      .add(this.options.cubeMapSize!, "x", -100, 100, 0.01)
      .name("cubeMapSizeX")
      .listen();
    this.debugFolder
      .add(this.options.cubeMapSize!, "y", -100, 100, 0.01)
      .name("cubeMapSizeY")
      .listen();
    this.debugFolder
      .add(this.options.cubeMapSize!, "z", -100, 100, 0.01)
      .name("cubeMapSizeZ")
      .listen();

    this.debugFolder
      .add(this.options.cubeMapPosition!, "x", -50, 50, 0.01)
      .name("cubeMapPosX")
      .listen();
    this.debugFolder
      .add(this.options.cubeMapPosition!, "y", -50, 50, 0.01)
      .name("cubeMapPosY")
      .listen();
    this.debugFolder
      .add(this.options.cubeMapPosition!, "z", -50, 50, 0.01)
      .name("cubeMapPosZ")
      .listen();
  }

  update(experience: PortfolioExperience) {
    this.cubeCamera.update(
      experience.renderManager.webGlRenderer,
      experience.activeScene,
    );
  }
}
