import Overlay from "../shared/Overlay.js";
import { PortfolioExperienceOptions } from "./options.js";
import PortfolioExperience from "../PortfolioExperience.js";

export type Page = {
  name: string;
  options: PortfolioExperienceOptions;
  texts: NodeListOf<HTMLElement>;
};

export enum PageTransitionState {
  Finished = 0,
  OverlayFadingIn = 1,
  OverlayFadingOut = 2,
}

export default class PagesManager {
  experience: PortfolioExperience;
  overlay: Overlay;

  pages: { [name: string]: Page };
  currentPage: Page | null;
  previousPage: Page | null;

  pageTransitionState: PageTransitionState;
  pageTransitionTimeline: gsap.core.Timeline | null;

  constructor(experience: PortfolioExperience) {
    this.experience = experience;
    this.overlay = experience.overlay;

    this.pages = {};
    this.currentPage = null;
    this.previousPage = null;

    this.pageTransitionState = PageTransitionState.Finished;
    this.pageTransitionTimeline = null;
  }

  addPage(
    name: string,
    options: PortfolioExperienceOptions,
    texts: NodeListOf<HTMLElement>,
  ) {
    this.pages[name] = { name, options, texts };
  }

  switchPageTo(newPageName: string) {
    if (this.currentPage !== null && this.currentPage.name === newPageName) {
      return;
    }

    if (!(newPageName in this.pages)) {
      console.error(
        `Page ${newPageName} has not been added. Use .addPage() to add it.`,
      );
    }

    const oldPage = this.currentPage;
    this.currentPage = this.pages[newPageName];
    this.overlay
      .transition(() => {
        oldPage?.options.teardown(this.experience);
        this.setupPage(newPageName);
      })
      .set(
        this.experience.html.nav,
        {
          pointerEvents: "none",
        },
        0,
      )
      .to(
        this.experience.html.nav,
        {
          opacity: 0,
          duration: 0.3,
        },
        0,
      )
      .to(
        this.experience.html.nav,
        {
          opacity: 1,
          duration: 0.3,
        },
        "2",
      )
      .set(
        this.experience.html.nav,
        {
          pointerEvents: "auto",
        },
        ">",
      );
  }

  setupPage(name: string) {
    if (!(name in this.pages)) {
      console.error(
        `Page ${name} has not been added. Use .addPage() to add it.`,
      );
    }

    const page = this.pages[name];

    this.experience.debugCamera?.applyOptions(page.options.debugCamera);

    this.experience.firstPersonCamera.applyOptions(
      page.options.firstPersonCamera,
    );

    this.experience.cameraCrane.applyOptions(page.options.cameraCrane);

    this.experience.boxBlurPass?.applyOptions(page.options.boxBlurPass);

    if (this.experience.unrealBloomPass != null) {
      if (page.options.unrealBloomPass.strength != null) {
        this.experience.unrealBloomPass.strength =
          page.options.unrealBloomPass.strength;
      }
      if (page.options.unrealBloomPass.radius != null) {
        this.experience.unrealBloomPass.radius =
          page.options.unrealBloomPass.radius;
      }
      if (page.options.unrealBloomPass.threshold != null) {
        this.experience.unrealBloomPass.threshold =
          page.options.unrealBloomPass.threshold;
      }
    }

    this.experience.filmicPass?.applyOptions(page.options.filmicPass);

    page.options.setup(this.experience);
  }
}
