precision highp float;

uniform vec2 resolution;
varying vec2 vUv;

uniform vec2 uvScale;
uniform float uvScaleMultiplier;
uniform vec2 uvDisplacement;
uniform vec2 uvDisplacementTo;
uniform vec2 uvDisplacementDirection;
uniform vec2 zPos;
uniform float zWidth;
uniform float zTopStrokeThickness;
uniform float zBottomStrokeThickness;
uniform float zInnerHeight;
uniform float zConnectorThickness;
uniform float zScale;
uniform float zAlpha;
uniform float centerZAlpha;
uniform float loading;
uniform float otherZMix;
uniform vec3 zColor;
uniform vec3 backgroundColor;

float triangle_area(vec2 a, vec2 b, vec2 c) {
	vec2 ab = vec2(b.x - a.x, b.y - a.y);
	vec2 ac = vec2(c.x - a.x, c.y - a.y);
	float product = ab.x * ac.y - ab.y * ac.x;

	return abs(product) / 2.0;
}

bool inside_triangle(vec2 uv, vec2 a, vec2 b, vec2 c) {
	float area1 = triangle_area(a, b, c);
	
	float area2 = triangle_area(a, b, uv)
		+ triangle_area(a, c, uv)
		+ triangle_area(b, c, uv);
	
	return abs(area1 - area2) < 0.00001;
}

bool z(
	vec2 uv,
	vec2 pos,
	float width,
	float topStrokeThickness,
	float bottomStrokeThickness,
	float innerHeight,
	float connectorThickness,
	float scale
) {
	float w = width * scale;
	float t = topStrokeThickness * scale;
	float b = bottomStrokeThickness * scale;
	float h = innerHeight * scale;
	float c = connectorThickness * scale;

	vec2 tsbl = pos + vec2(-w / 2.0, h / 2.0); // top stroke's bottom left

    vec2 topStroke = step(tsbl, uv) - step(tsbl + vec2(w, t), uv);
    bool insideTopStroke = topStroke.x * topStroke.y == 1.0;

    vec2 bottomStroke =
		  step(tsbl - vec2(0, h + b), uv)
		- step(tsbl + vec2(w, -h), uv);
    bool insideBottomStroke = bottomStroke.x * bottomStroke.y == 1.0;

	vec2 bounding_box =
		  step(tsbl - vec2(0, h + b), uv)
		- step(tsbl + vec2(w, t), uv);
	bool insideBoundingBox = bounding_box.x * bounding_box.y == 1.0;

	vec2 a1 = tsbl;
	vec2 b1 = vec2(a1.x + w - c, a1.y);
	vec2 c1 = vec2(a1.x, a1.y - h);

	bool insideTriangle1 = inside_triangle(uv, a1, b1, c1);

	vec2 a2 = vec2(tsbl.x + w, tsbl.y);
	vec2 b2 = vec2(a2.x, a2.y - h);
	vec2 c2 = vec2(b2.x - w + c, b2.y);

	bool insideTriangle2 = inside_triangle(uv, a2, b2, c2);

    return insideTopStroke || insideBottomStroke
		|| (!insideTriangle1 && !insideTriangle2 && insideBoundingBox);
}

void main() {
    vec2 uv = vUv * 2.0 - 1.0;
    uv.x *= resolution.x / resolution.y;

	vec2 uv2 = uv * uvScale * uvScaleMultiplier + vec2(0.5);
	vec2 uvDisplaced = uv2 + uvDisplacement
		* (mod(floor(uv2), 2.0).yx * 2.0 - 1.0); // alternating scroll directions

	uv = fract(uvDisplaced);

	vec2 pos = zPos * uvScale;
    
	bool is_z = z(
		uv,
		pos,
		zWidth * uvScale.x,
		zTopStrokeThickness * uvScale.y,
		zBottomStrokeThickness * uvScale.y,
		zInnerHeight * uvScale.y,
		zConnectorThickness * uvScale.x,
		zScale
	);
    vec4 color = vec4(backgroundColor, 1);

	if (is_z) {
		bool loaded = uv.y <
			  ((pos.y + zInnerHeight / 2.0 + zTopStrokeThickness)
			- (pos.y - zInnerHeight / 2.0 - zBottomStrokeThickness))
			* loading + (pos.y - zInnerHeight / 2.0 - zBottomStrokeThickness);

		float alpha = 1.0;
		vec2 zIndex = floor(uv2 - uvDisplacement);

		color.xyz = zColor;

		if (zIndex == vec2(0) || zIndex == -uvDisplacementTo) {
			alpha = centerZAlpha;
		} else {
			alpha = zAlpha;

			float i = otherZMix
				/ (otherZMix + (zIndex.x + zIndex.y + 5.7) * (1.0 - otherZMix));

			color.xyz = mix(
				backgroundColor,
				zColor,
				clamp(i, 0.0, 1.0)
			);
		}

		color = vec4(vec3(loaded) * color.xyz, alpha);
	}

    gl_FragColor = color;
}