import { Vector3 } from "three";

/**
 * The two possible viewport orientations, portrait and landscape.
 */
export type ViewportOrientation = "portrait" | "landscape";

/**
 * The Device information class.
 *
 * This class contains some information about the user's device, including the
 * viewport orientation, the device orientation, and whether or not the device
 * is a mobile device.
 *
 * ## Events
 *
 * You can use {@link EventTarget.addEventListener} to listen to events
 * supported by the `Device`.
 *
 * The following {@link CustomEvent}s are supported:
 *
 * - `viewportorientation`: Dispatched when the viewport orientation has changed
 *   i.e. from portrait to landscape and vice versa.
 * - `orientation`: Dispatched when the device orientation has changed.
 *
 * The `detail` property of the custom event is set to the `Device` instance.
 */
export default class Device extends EventTarget {
  /**
   * The orientation of the viewport (portrait or landscape).
   *
   * @readonly
   */
  viewportOrientation: ViewportOrientation;
  /**
   * The orientation of the device associated with the Earth's gravity pull.
   *
   * @readonly
   */
  orientation: Vector3;
  /**
   * Whether or not the device is a phone e.g. iPhone, Android.
   *
   * @readonly
   */
  isPhone: boolean;
  /**
   * Whether or not the device is a tablet e.g. iPad.
   *
   * @readonly
   */
  isTablet: boolean;

  /**
   * Creates a new device tracking instance and registers listeners for updating
   * information.
   */
  constructor() {
    super();

    this.viewportOrientation =
      window.innerWidth < window.innerHeight ? "portrait" : "landscape";
    this.orientation = new Vector3();

    this.isPhone = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(
        navigator.userAgent,
      );

    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener(
      "deviceorientation",
      this.deviceorientation.bind(this),
    );
  }

  /**
   * Updates the information in this instance after the window has been resized.
   */
  private resize() {
    const oldViewportOrientation = this.viewportOrientation;
    this.viewportOrientation =
      window.innerWidth < window.innerHeight ? "portrait" : "landscape";

    if (this.viewportOrientation != oldViewportOrientation) {
      this.dispatchEvent(
        new CustomEvent("viewportorientation", { detail: this }),
      );
    }
  }

  /**
   * Updates the information in this instance after the device orientation has
   * changed.
   */
  private deviceorientation(event: DeviceOrientationEvent) {
    if (event.alpha !== null && event.beta !== null && event.gamma !== null) {
      this.orientation.set(event.alpha, event.beta, event.gamma);
    }
  }
}
