import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  MeshPhysicalMaterial,
  Object3D,
  Texture,
} from "three";

export default class Stage {
  model: Object3D;
  bakeMaterial: MeshBasicMaterial;
  glassFenceMaterial: MeshPhysicalMaterial;
  exitTextMaterial: MeshBasicMaterial;
  captionMaterial: MeshBasicMaterial;

  constructor(model: Object3D, bake: Texture) {
    this.model = model;

    // Baked material

    bake.flipY = false;

    this.bakeMaterial = new MeshBasicMaterial({
      map: bake,
    });

    this.model.traverse((child) => {
      if (Object.hasOwn(child, "material") && child.name !== "Glass_fence") {
        (child as Mesh).material = this.bakeMaterial;
      }
    });

    // Glass fence material

    this.glassFenceMaterial = new MeshPhysicalMaterial({
      color: 0xe3e3e3,
      roughness: 0,
      metalness: 0,
      ior: 1.49,
      transmission: 1.0,
      side: DoubleSide,
      flatShading: true,
    });

    (this.model.getObjectByName("Glass_fence") as Mesh).material =
      this.glassFenceMaterial;

    // Exit text material

    this.exitTextMaterial = new MeshBasicMaterial({
      color: 0xff0000,
    });

    (this.model.getObjectByName("First_floor_exit_text") as Mesh).material =
      this.exitTextMaterial;
    (this.model.getObjectByName("Second_floor_exit_text") as Mesh).material =
      this.exitTextMaterial;
    (this.model.getObjectByName("Second_floor_exit_text_2") as Mesh).material =
      this.exitTextMaterial;

    // Painting caption material

    this.captionMaterial = new MeshBasicMaterial({
      color: 0x000000,
    });

    (
      this.model.getObjectByName("Myself_painting_desktop_caption") as Mesh
    ).material = this.captionMaterial;
  }
}
