import { PerspectiveCamera, CameraHelper } from "three";
import Debugable from "../interfaces/Debugable.js";
import GUI from "lil-gui";
import Options from "../interfaces/Options.js";

export type DebuggableCameraOptions = {
  fov: number;
  near: number;
  far: number;
};

/**
 * A wrapper class around {@link PerspectiveCamera} that includes a debug
 * folder.
 */
export default class DebuggableCamera
  extends PerspectiveCamera
  implements Debugable, Options
{
  readonly options: DebuggableCameraOptions;

  debugFolder?: GUI;
  helper?: THREE.CameraHelper;

  constructor(width: number, height: number, options: DebuggableCameraOptions) {
    super();

    this.options = { ...options };
    this.applyOptions(options);
    this.setSize(width, height);
  }

  applyOptions(options: DebuggableCameraOptions) {
    this.fov = options.fov;
    this.near = options.near;
    this.far = options.far;
    this.updateProjectionMatrix();
  }

  setSize(width: number, height: number) {
    this.aspect = width / height;
    this.updateProjectionMatrix();
  }

  setDebugFolder(debugGui: GUI, name: string) {
    this.debugFolder = debugGui.addFolder(name);

    // Camera configurator

    const updateMatrixAndHelper = () => {
      this.updateProjectionMatrix();
      this.helper!.update();
    };

    this.debugFolder
      .add(this, "fov", 25, 120, 1)
      .onChange(updateMatrixAndHelper)
      .listen();
    this.debugFolder
      .add(this, "near", 0.01, 1, 0.01)
      .onChange(updateMatrixAndHelper)
      .listen();
    this.debugFolder
      .add(this, "far", 1, 100, 1)
      .onChange(updateMatrixAndHelper)
      .listen();

    // Camera helper

    this.helper = new CameraHelper(this);

    this.debugFolder.add(this.helper, "visible");
  }
}
