import { ShaderMaterial, WebGLRenderer, WebGLRenderTarget } from "three";
import {
  Pass,
  FullScreenQuad,
} from "three/examples/jsm/postprocessing/Pass.js";

import vertexShader from "../shaders/postprocessing/boxblur.vert.glsl";
import fragmentShader from "../shaders/postprocessing/boxblur.frag.glsl";
import Options from "../../interfaces/Options.js";

export type BoxBlurPassOptions = {
  size?: number;
  separation?: number;
};

export class BoxBlurPass extends Pass implements Options {
  options: BoxBlurPassOptions;

  material: ShaderMaterial;

  private fullScreenQuad: FullScreenQuad;

  constructor(width: number, height: number, options: BoxBlurPassOptions = {}) {
    super();

    this.options = {
      size: 3,
      separation: 1,
    };

    this.material = new ShaderMaterial({
      uniforms: {
        width: { value: width },
        height: { value: height },
        tDiffuse: { value: null },
        size: { value: this.options.size },
        separation: { value: this.options.separation },
      },
      vertexShader,
      fragmentShader,
    });

    this.applyOptions(options);

    this.fullScreenQuad = new FullScreenQuad(this.material);
  }

  applyOptions(options?: BoxBlurPassOptions): void {
    if (options?.size !== undefined) {
      this.options.size = options.size;
    }

    if (options?.separation !== undefined) {
      this.options.separation = options.separation;
    }

    this.material.uniforms.size.value = this.options.size;
    this.material.uniforms.separation.value = this.options.separation;
  }

  setSize(width: number, height: number) {
    this.material.uniforms.width.value = width;
    this.material.uniforms.width.value = height;
  }

  render(
    renderer: WebGLRenderer,
    writeBuffer: WebGLRenderTarget,
    readBuffer: WebGLRenderTarget,
  ) {
    this.material.uniforms.tDiffuse.value = readBuffer.texture;

    if (this.renderToScreen) {
      renderer.setRenderTarget(null);
      this.fullScreenQuad.render(renderer);
    } else {
      renderer.setRenderTarget(writeBuffer);
      renderer.clear();
      this.fullScreenQuad.render(renderer);
    }
  }
}
