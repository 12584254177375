import {
  Box3,
  Font,
  Mesh,
  MeshBasicMaterial,
  TextGeometry,
  Vector3,
} from "three";

export enum PaintingDirection {
  NegativeX,
  NegativeZ,
  PositiveX,
  PositiveZ,
}

export enum CaptionRelativePosition {
  ToTheLeft = -1,
  ToTheRight = 1,
  Under = 0,
}

export type PaintingOptions = {
  direction: PaintingDirection;
  caption: string;
  horizontalSpacing: number;
  verticalSpacing: number;
};

export default class Painting {
  mesh: Mesh;
  options: PaintingOptions;

  landscapeCaptionMesh: Mesh;
  portraitCaptionMesh: Mesh;

  constructor(
    mesh: Mesh,
    options: PaintingOptions,
    desktopCaptionMesh: Mesh,
    mobileCaptionMesh: Mesh,
  ) {
    this.mesh = mesh;
    this.options = options;
    this.landscapeCaptionMesh = desktopCaptionMesh;
    this.portraitCaptionMesh = mobileCaptionMesh;
  }

  setCaptionMesh(font: Font, color: number) {
    const geometry = new TextGeometry(this.options.caption, {
      font,
      size: 0.0225,
      height: 0,
    });
    const material = new MeshBasicMaterial({ color });

    this.landscapeCaptionMesh = new Mesh(geometry, material);
  }

  generateCaptionMeshPosition(
    relativePosition: CaptionRelativePosition,
  ): Vector3 {
    if (!this.landscapeCaptionMesh) {
      console.error(
        "Cannot generate a caption mesh position without a caption mesh, call .setCaptionMesh() first.",
      );
    }

    const paintingSize = new Box3()
      .setFromObject(this.mesh)
      .getSize(new Vector3());
    const captionMeshSize = new Box3()
      .setFromObject(this.landscapeCaptionMesh)
      .getSize(new Vector3());

    const captionMeshPosition = this.mesh.position.clone();
    captionMeshPosition.y += captionMeshSize.y / 2;

    // Shift the caption mesh to the relative position first.

    if (relativePosition === CaptionRelativePosition.Under) {
      const shift = paintingSize.y / 2 + this.options.verticalSpacing;

      captionMeshPosition.y -= shift;
    } else {
      // if relative position is left or right
      const shift = paintingSize.x / 2 + this.options.horizontalSpacing;

      switch (this.options.direction) {
        case PaintingDirection.NegativeX:
          captionMeshPosition.z += shift * relativePosition;
          break;
        case PaintingDirection.NegativeZ:
          captionMeshPosition.x -= shift * relativePosition;
          break;
        case PaintingDirection.PositiveX:
          captionMeshPosition.z -= shift * relativePosition;
          break;
        case PaintingDirection.PositiveZ:
          captionMeshPosition.x += shift * relativePosition;
          break;
      }
    }

    // Then shift the caption mesh towards the wall so it looks like it's on
    // the wall instead of floating. This assumes that the painting mesh's
    // position is centered at the painting in all 3 axes.

    const shift =
      this.options.direction === PaintingDirection.NegativeX ||
      this.options.direction === PaintingDirection.PositiveX
        ? paintingSize.x / 2
        : paintingSize.z / 2;

    switch (this.options.direction) {
      case PaintingDirection.NegativeX:
        captionMeshPosition.x += shift;
        break;
      case PaintingDirection.NegativeZ:
        captionMeshPosition.z += shift;
        break;
      case PaintingDirection.PositiveX:
        captionMeshPosition.x -= shift;
        break;
      case PaintingDirection.PositiveZ:
        captionMeshPosition.z -= shift;
        break;
    }

    return captionMeshPosition;
  }

  getCenter(mobile: boolean): Vector3 {
    const center = this.mesh.position.clone();
    const captionMesh = mobile
      ? this.portraitCaptionMesh
      : this.landscapeCaptionMesh;

    if (captionMesh !== undefined) {
      center.add(captionMesh.position).divideScalar(2);
    }

    return center;
  }

  getViewpoint(distanceFromPainting: number, mobile: boolean): Vector3 {
    const viewpoint = this.getCenter(mobile);

    switch (this.options.direction) {
      case PaintingDirection.NegativeX:
        viewpoint.add(new Vector3(-distanceFromPainting, 0, 0));
        break;
      case PaintingDirection.NegativeZ:
        viewpoint.add(new Vector3(0, 0, -distanceFromPainting));
        break;
      case PaintingDirection.PositiveX:
        viewpoint.add(new Vector3(distanceFromPainting, 0, 0));
        break;
      case PaintingDirection.PositiveZ:
        viewpoint.add(new Vector3(0, 0, distanceFromPainting));
        break;
    }

    return viewpoint;
  }
}
