export default [
  {
    name: "stageModel",
    type: "gltfModel",
    path: "models/Stage/stage.glb",
  },
  {
    name: "stageBakedTexture",
    type: "texture",
    path: "models/Stage/baked.jpg",
  },
  {
    name: "galleryModel",
    type: "gltfModel",
    path: "models/Stage/gallery.glb",
  },
  {
    name: "galleryBakedTexture",
    type: "texture",
    path: "models/Stage/gallery_bake.jpg",
  },
  // {
  //   name: "paintingCaptionFont",
  //   type: "font",
  //   path: "fonts/Helvetiker/helvetiker_bold.typeface.json",
  // },
];
