#include <packing>

varying vec2 vUv;

uniform float lensDistortion, cubeDistortion, width, height, chromaticDispersion,
	scale, noiseAmount, nearClip, farClip, focusZ, near, far, renderDepth;
uniform int frame;
uniform sampler2D tDiffuse, tBlur, tViewPosition, tDepth;

float getDepth(const in vec2 screenPosition) {
	#if DEPTH_PACKING == 1
	return unpackRGBAToDepth(texture2D(tDepth, screenPosition));
	#else
	return texture2D(tDepth, screenPosition).x;
	#endif
}

float getViewZ(const in float depth) {
	#if PERSPECTIVE_CAMERA == 1
	return perspectiveDepthToViewZ(depth, nearClip, farClip);
	#else
	return orthographicDepthToViewZ(depth, nearClip, farClip);
	#endif
}

#if DEPTH_OF_FIELD == 1

vec4 depthOfField(const in vec2 uv) {
	vec4 focus = texture2D(tDiffuse, uv);
	vec4 outOfFocus = texture2D(tBlur, uv);
	float viewZ = getViewZ(getDepth(uv));

	float nViewZ = clamp((viewZ - near) / (far - near), 0.0, 1.0);
	float nFocusZ = clamp((focusZ - near) / (far - near), 0.0, 1.0);
	float invViewZ = 1.0 - nViewZ; // Closest is 1.0, furthest is 0.0
	float invFocusZ = 1.0 - nFocusZ; // Closest is 1.0, furthest is 0.0
	float dof = smoothstep(0.0, 1.0, abs(invViewZ - invFocusZ));

	return mix(focus, outOfFocus, dof);
}

#endif

void main() {
	// Cube distortion

	float aspectRatio = width / height;
	float r2 = aspectRatio * aspectRatio * dot(vUv - 0.5, vUv - 0.5);

	// float f = 1.0 + r2 * (lensDistortion + cubeDistortion * sqrt(r2));
	float f = 1.0 + r2 * lensDistortion; // remove the cube distortion computation

	// Chromatic dispersion

	// Ratio of index of refraction
	vec3 eta = vec3(
		1.0 + chromaticDispersion * 0.9,
		1.0 + chromaticDispersion * 0.6,
		1.0 + chromaticDispersion * 0.3
	);

	vec2 rCoords = (f * eta.r) * (vUv - 0.5) * scale + 0.5;
	vec2 gCoords = (f * eta.g) * (vUv - 0.5) * scale + 0.5;
	vec2 bCoords = (f * eta.b) * (vUv - 0.5) * scale + 0.5;

	#if DEPTH_OF_FIELD == 1

	vec4 color = vec4(
		depthOfField(rCoords).r,
		depthOfField(gCoords).g,
		depthOfField(bCoords).b,
		1.0
	);

	#else

	vec4 color = vec4(
		texture2D(tDiffuse, rCoords).r,
		texture2D(tDiffuse, gCoords).g,
		texture2D(tDiffuse, bCoords).b,
		1.0
	);

	#endif

	// Noise

	float toRadians = 3.14 / 180.0;
	float randomNoiseIntensity = fract(
		10000.0 * sin((gl_FragCoord.x + gl_FragCoord.y * float(999 + frame % 999))
			* toRadians)
	);

	color.rgb += noiseAmount * randomNoiseIntensity;

	float viewZ = getViewZ(getDepth(vUv));
	float nViewZ = clamp((viewZ - near) / (far - near), 0.0, 1.0);
	float invViewZ = 1.0 - nViewZ; // Closest is 1.0, furthest is 0.0

	gl_FragColor = mix(color, vec4(vec3(invViewZ), 1.0), renderDepth);
}
