/**
 * The Time information class, which contains information such as the elapsed
 * time since tracking starts, the elapsed time since last animation frame, and
 * so on.
 *
 * @remarks
 *
 * You can use {@link EventTarget.addEventListener} to listen to the time tick.
 * The `tick` event is dispatched every animation frame, and contains a
 * {@link CustomEvent} whose {@link CustomEvent.detail | detail} property is set
 * to this instance.
 *
 * @example Using the `tick` event
 *
 * ```ts
 * const time = new Time();
 *
 * time.addEventListener("tick", (event: Event) => {
 *   const { elapsedMs, deltaMs } = (event as CustomEvent).detail;
 *
 *   // ...
 * });
 * ```
 */
export default class Time extends EventTarget {
  /**
   * The timestamp of when this instance started tracking the time, as indicated
   * by the number of milliseconds elapsed since midnight, January 1, 1970 UTC.
   *
   * @readonly
   */
  start: number;
  /**
   * The timestamp of the latest animation frame, as indicated by the number of
   * milliseconds elapsed since midnight, January 1, 1970 UTC.
   *
   * @readonly
   */
  current: number;
  /**
   * The number of milliseconds elapsed since time tracking began.
   *
   * @readonly
   */
  elapsedMs: number;
  /**
   * The number of milliseconds elapsed between the previous animation frame and
   * the current animation frame.
   *
   * @readonly
   */
  deltaMs: number;
  /**
   * The number of animation frames since time tracking began.
   *
   * @readonly
   */
  frame: number;
  /**
   * The request ID of the last animation frame returned by
   * {@link Window.requestAnimationFrame}.
   *
   * @readonly
   */
  frameId: number;

  /**
   * Creates a new time tracking instance and begins the animation loop.
   */
  constructor() {
    super();

    this.start = Date.now();
    this.current = this.start;
    this.elapsedMs = 0;
    this.deltaMs = 0;
    this.frame = 0;
    this.frameId = window.requestAnimationFrame(this.tick.bind(this));
  }

  private tick() {
    const currentTime = Date.now();
    this.deltaMs = currentTime - this.current;
    this.current = currentTime;
    this.elapsedMs = this.current - this.start;
    this.frame++;

    this.dispatchEvent(new CustomEvent("tick", { detail: this }));

    this.frameId = window.requestAnimationFrame(this.tick.bind(this));
  }
}
