uniform int width, height, size;
uniform float separation;
uniform sampler2D tDiffuse;

varying vec2 vUv;

void main() {
  vec4 blur = vec4(0);
  int edge = (size - 1) / 2;

	for (int i = -edge; i <= edge; ++i) {
		for (int j = -edge; j <= edge; ++j) {
			blur += texture2D(
        tDiffuse,
        (vUv + ((vec2(i, j) * separation) / vec2(width, height)))
      );
		}
	}

	blur /= float(size * size);

  gl_FragColor = blur;
}
