import { Vector3 } from "three";
import { PortfolioExperienceOptions } from "../options.js";
import Mouse from "../../utils/Mouse.js";
import CameraCrane from "../../cameras/CameraCrane.js";
import PortfolioExperience from "../../PortfolioExperience.js";
import gsap from "gsap";

let gimbalMouseTween: gsap.core.Tween;
let gimbalZTween: gsap.core.Tween;
let moveGimbalCallback: EventListenerOrEventListenerObject;

function moveGimbal(cameraCrane: CameraCrane, mouse: Mouse) {
  if (gimbalMouseTween !== undefined) {
    gimbalMouseTween.kill();
  }

  gimbalMouseTween = gsap.to(cameraCrane.gimbal.position, {
    x: mouse.ndc!.x * 0.3,
    y: mouse.ndc!.y * 0.3 + 1.5,
    duration: 1,
  });
}

export const homePageOptions: PortfolioExperienceOptions = {
  debugCamera: {
    fov: 35,
    near: 0.1,
    far: 100,
  },
  firstPersonCamera: {
    fov: 63,
    near: 1,
    far: 25,
  },
  cameraCrane: {
    path: null,
  },
  boxBlurPass: {
    size: 3,
    separation: 0.3,
  },
  unrealBloomPass: {
    enabled: true,
    strength: 0.2,
    radius: 1,
    threshold: 0.8,
  },
  filmicPass: {
    lensDistortion: 0,
    cubeDistortion: 0,
    chromaticDispersion: 0.012,
    scale: 0.98,
    noiseAmount: 0.02,
    near: -6.7, // -2 is nearest
    far: -10.9, // -20 is farthest
    blurSize: 5,
    blurSeparation: 1,
  },
  setup: (experience: PortfolioExperience) => {
    const {
      floor,
      roof,
      stage,
      gallery,
      galleryCameraPathPortrait,
      galleryCameraPathLandscape,
      debugCamera,
      orbitControls,
      cameraCrane,
      monitor,
      device,
      mouse,
      loadingTimeline,
      filmicPass,
    } = experience;

    // World

    stage!.model.visible = true;
    gallery!.model.visible = false;
    galleryCameraPathPortrait.helper.visible = false;
    galleryCameraPathLandscape.helper.visible = false;

    floor?.applyOptions({
      cubeCameraResolution: 1024,
      cubeMapSize: new Vector3(50, 50, 50),
      cubeMapPosition: new Vector3(0, 0, 13.62),
    });

    roof?.applyOptions({
      cubeCameraResolution: 1024,
      cubeMapSize: new Vector3(50, 50, 50),
      cubeMapPosition: new Vector3(0, 0, 13.62),
    });

    monitor?.applyOptions({
      width: 7.56,
      height: 7.68,
      mouseTweenDuration: 1,
      mouseTweenDelay: 0.05,
      displayTweenDuration: 0.6,
    });

    // Debug camera

    debugCamera?.position.set(0, 3.91, 10);
    orbitControls?.target.set(0, 3.91, 0);

    // Camera crane

    cameraCrane.applyOptions({
      target: monitor!.mesh.position.clone().setComponent(1, 2),
    });
    delete cameraCrane.options.path;

    cameraCrane.gimbal.position.set(0, 1.5, 0);

    // Focus once, but not in update
    cameraCrane.focus();
    cameraCrane.gimbal.rotation.y = Math.PI;

    moveGimbalCallback = moveGimbal.bind(null, cameraCrane, mouse);

    loadingTimeline.vars.onComplete = () => {
      gimbalZTween = gsap.to(cameraCrane.gimbal.position, {
        z: -1,
        duration: 18,
        ease: "sine.in",
      });
    };

    if (!device.isPhone && !device.isTablet) {
      mouse.addEventListener("move", moveGimbalCallback);
    }
  },
  update: (experience: PortfolioExperience) => {
    if (experience.monitor) experience.monitor.update(experience);
    if (experience.roof) experience.roof.update(experience);
  },
  teardown: (experience: PortfolioExperience) => {
    const { mouse } = experience;

    if (gimbalMouseTween != null) {
      gimbalMouseTween.kill();
    }

    if (gimbalZTween != null) {
      gimbalZTween.kill();
    }

    mouse.removeEventListener("move", moveGimbalCallback);
  },
};
