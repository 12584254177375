import { Color, Vector2 } from "three";
import PortfolioExperience from "../PortfolioExperience.js";
import { DebuggableCameraOptions } from "../cameras/DebuggableCamera.js";
import { OverlayOptions } from "../shared/Overlay.js";
import { PaintingDirection, PaintingOptions } from "./world/Painting.js";
import { FilmicPassOptions } from "./postprocessing/FilmicPass.js";
import { homePageOptions } from "./options/homePageOptions.js";
import { aboutPageOptions } from "./options/aboutPageOptions.js";
import { workPageOptions } from "./options/workPageOptions.js";
import { CameraCraneOptions } from "../cameras/CameraCrane.js";
import { BoxBlurPassOptions } from "./postprocessing/BoxBlurPass.js";

export type PortfolioExperienceOptions = {
  debugCamera: DebuggableCameraOptions;
  firstPersonCamera: DebuggableCameraOptions;
  cameraCrane: CameraCraneOptions;
  boxBlurPass: BoxBlurPassOptions;
  unrealBloomPass: {
    enabled?: boolean;
    strength?: number;
    radius?: number;
    threshold?: number;
  };
  filmicPass: FilmicPassOptions;

  setup: (experience: PortfolioExperience) => void;
  update: (experience: PortfolioExperience) => void;
  teardown: (experience: PortfolioExperience) => void;
};

export { homePageOptions, aboutPageOptions, workPageOptions };

export const overlayOptions: OverlayOptions = {
  zPos: new Vector2(0.5, 0.61),
  zWidth: 1.0,
  zTopStrokeThickness: 0.291084677,
  zBottomStrokeThickness: 0.291084677,
  zInnerHeight: 0.637957475,
  zConnectorThickness: 0.433787873,
  zScale: 0.75,
  zColor: new Color(0xffffff),
  backgroundColor: new Color(0x000000),
};

export const myselfPaintingOptions: PaintingOptions = {
  direction: PaintingDirection.NegativeZ,
  caption: `
Zach Nguyen is a builder who loves crafting at the intersection of
art and tech. He has a knack for delivering uniqueness and
attention to details in his work.

Zach loves building with 3D stuff, rendering algorithms, GPUs, Rust,
TypeScript, and Web technologies. He believes the Web is the
future platform for inclusive software.

Zach hails from Hanoi, Vietnam and is currently a senior at the
University of Rochester. He is open to software engineering,
graphics programming, and VFX technical director roles.
`,
  horizontalSpacing: 0.079905,
  verticalSpacing: 0.079905,
};
