import { LoadingManager, TextureLoader, FontLoader } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export type Sources = Array<{
  name: string;
  type: "gltfModel" | "texture";
  path: string;
}>;

/**
 * Class for loading and holding resources.
 *
 * @remarks
 *
 * The {@link EventTarget.addEventListener} can be used to listen to events.
 * Supported events are:
 *
 * - `load`: Dispatched when all resources have been loaded (or when there are
 *           no resources).
 * - `progress`: Dispatched when a resource has been loaded.
 * - `error`: Dispatched when a resource failed to load.
 *
 * The event dispatched will be a {@link CustomEvent} with the
 * {@link CustomEvent.detail | detail} property set to the `Resources` instance.
 */
export default class Resources extends EventTarget {
  /**
   * The list of resources to be loaded.
   *
   * @readonly
   */
  readonly sources: Sources;
  /**
   * The object that contains the loaded resources. The name of the resource
   * will be used as the key.
   *
   * @readonly
   */
  readonly items: { [key: string]: any };
  /**
   * The total number of resources that need to be loaded.
   *
   * @readonly
   */
  total: number;
  /**
   * The number of resources that have been loaded so far.
   *
   * @readonly
   */
  progress: number;
  /**
   * The number of resources that failed to load.
   */
  errors: number;

  private loadingManager: THREE.LoadingManager;
  private loaders: {
    gltfLoader: GLTFLoader;
    textureLoader: THREE.TextureLoader;
    fontLoader: FontLoader;
  };

  /**
   * Creates a new instance of `Resources` for the specified list of sources.
   * This will not begin loading the resources; use {@link Resources.load} for
   * that.
   *
   * @param sources The sources from which to load resources.
   */
  constructor(sources: Sources) {
    super();

    this.sources = sources;
    this.items = {};
    this.total = this.sources.length;
    this.progress = 0;
    this.errors = 0;

    this.loadingManager = new LoadingManager();

    this.loadingManager.onProgress = (_url, _progress, _total) => {
      this.progress++;
      this.dispatchEvent(new CustomEvent("progress", { detail: this }));
    };

    this.loadingManager.onLoad = () => {
      this.dispatchEvent(new CustomEvent("load", { detail: this }));
    };

    this.loadingManager.onError = (_url) => {
      this.errors++;
      this.dispatchEvent(new CustomEvent("error", { detail: this }));
    };

    this.loaders = {
      gltfLoader: new GLTFLoader(this.loadingManager),
      textureLoader: new TextureLoader(this.loadingManager),
      fontLoader: new FontLoader(this.loadingManager),
    };

    if (this.total === 0) {
      this.dispatchEvent(new CustomEvent("load", { detail: this }));
    }
  }

  /**
   * Begin loading the resource with the specified name, or all of the resources
   * if not specified.
   *
   * @param name   The name of the resource to load (optional).
   * @param reload If a resource has already been loaded, whether or not the
   *               resource should be reloaded.
   */
  load(name?: string, reload: boolean = false) {
    const sources =
      name !== undefined
        ? this.sources.filter((source) => source.name === name)
        : this.sources;

    sources.forEach((source) => {
      if (this.items[source.name] !== undefined && !reload) {
        return; // resource has already been loaded
      }

      if (source.type === "gltfModel") {
        this.loaders.gltfLoader.load(source.path, (gltf) => {
          this.items[source.name] = gltf;
        });
      } else if (source.type === "texture") {
        this.loaders.textureLoader.load(source.path, (texture) => {
          this.items[source.name] = texture;
        });
      }
    });
  }
}
