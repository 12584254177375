import {
  Scene,
  MeshDepthMaterial,
  RGBADepthPacking,
  NoBlending,
  WebGLRenderTarget,
  NearestFilter,
  ShaderMaterial,
  Color,
  PerspectiveCamera,
  OrthographicCamera,
  WebGLRenderer,
} from "three";
import {
  Pass,
  FullScreenQuad,
} from "three/examples/jsm/postprocessing/Pass.js";
import boxblurVert from "../shaders/postprocessing/boxblur.vert.glsl";
import boxblurFrag from "../shaders/postprocessing/boxblur.frag.glsl";
import filmicVert from "../shaders/postprocessing/filmic.vert.glsl";
import filmicFrag from "../shaders/postprocessing/filmic.frag.glsl";
import Options from "../../interfaces/Options.js";

type FilmicPassOptions = {
  lensDistortion?: number;
  cubeDistortion?: number;
  chromaticDispersion?: number;
  scale?: number;
  noiseAmount?: number;
  focusZ?: number;
  near?: number;
  far?: number;
  blurSize?: number;
  blurSeparation?: number;
  renderDepth?: boolean;
};

class FilmicPass extends Pass implements Options {
  scene: Scene;
  camera: PerspectiveCamera | OrthographicCamera;
  options: FilmicPassOptions;
  blurMaterial: ShaderMaterial;
  filmicMaterial: ShaderMaterial;

  private depthMaterial: MeshDepthMaterial;
  private depthRenderTarget: WebGLRenderTarget;
  private blurRenderTarget: WebGLRenderTarget;
  private blurQuad: FullScreenQuad;
  private fullScreenQuad: FullScreenQuad;
  private oldClearColor: Color;

  constructor(
    scene: Scene,
    camera: PerspectiveCamera | OrthographicCamera,
    width: number,
    height: number,
    depthOfField: boolean,
    options?: FilmicPassOptions,
  ) {
    super();

    this.options = {
      lensDistortion: 0,
      cubeDistortion: 0,
      chromaticDispersion: 0,
      scale: 1,
      noiseAmount: 0,
      focusZ: 0,
      near: 0.1,
      far: 2000,
      blurSize: 1,
      blurSeparation: 1,
      renderDepth: false,
    };

    this.scene = scene;
    this.camera = camera;

    this.depthRenderTarget = new WebGLRenderTarget(width / 2, height / 2, {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
    });

    this.depthMaterial = new MeshDepthMaterial();
    this.depthMaterial.depthPacking = RGBADepthPacking;
    this.depthMaterial.blending = NoBlending;

    this.blurRenderTarget = new WebGLRenderTarget(width / 2, height / 2);

    this.blurMaterial = new ShaderMaterial({
      uniforms: {
        width: { value: null },
        height: { value: null },
        tDiffuse: { value: null },
        size: { value: this.options.blurSize },
        separation: { value: this.options.blurSeparation },
      },
      vertexShader: boxblurVert,
      fragmentShader: boxblurFrag,
    });

    this.filmicMaterial = new ShaderMaterial({
      defines: {
        DEPTH_PACKING: 1,
        PERSPECTIVE_CAMERA: 1,
        DEPTH_OF_FIELD: +depthOfField,
      },
      uniforms: {
        width: { value: null },
        height: { value: null },
        lensDistortion: { value: this.options.lensDistortion },
        cubeDistortion: { value: this.options.cubeDistortion },
        chromaticDispersion: { value: this.options.chromaticDispersion },
        scale: { value: this.options.scale },
        noiseAmount: { value: this.options.noiseAmount },
        frame: { value: 0 },
        tDiffuse: { value: null },
        tDepth: { value: this.depthRenderTarget.texture },
        tBlur: { value: this.blurRenderTarget.texture },
        nearClip: { value: null },
        farClip: { value: null },
        focusZ: { value: 0 },
        near: { value: this.options.near },
        far: { value: this.options.far },
        renderDepth: { value: false },
      },
      vertexShader: filmicVert,
      fragmentShader: filmicFrag,
    });

    this.applyOptions(options);

    this.blurQuad = new FullScreenQuad(this.blurMaterial);
    this.fullScreenQuad = new FullScreenQuad(this.filmicMaterial);
    this.oldClearColor = new Color();
  }

  applyOptions(options?: FilmicPassOptions) {
    if (options?.lensDistortion !== undefined) {
      this.options.lensDistortion = options.lensDistortion;
    }
    if (options?.cubeDistortion !== undefined) {
      this.options.cubeDistortion = options.cubeDistortion;
    }
    if (options?.chromaticDispersion !== undefined) {
      this.options.chromaticDispersion = options.chromaticDispersion;
    }
    if (options?.scale !== undefined) {
      this.options.scale = options.scale;
    }
    if (options?.noiseAmount !== undefined) {
      this.options.noiseAmount = options.noiseAmount;
    }
    if (options?.focusZ !== undefined) {
      this.options.focusZ = options.focusZ;
    }
    if (options?.near !== undefined) {
      this.options.near = options.near;
    }
    if (options?.far !== undefined) {
      this.options.far = options.far;
    }
    if (options?.blurSize !== undefined) {
      this.options.blurSize = options.blurSize;
    }
    if (options?.blurSeparation !== undefined) {
      this.options.blurSeparation = options.blurSeparation;
    }
    if (options?.renderDepth !== undefined) {
      this.options.renderDepth = options.renderDepth;
    }

    this.blurMaterial.uniforms.size.value = this.options.blurSize;
    this.blurMaterial.uniforms.separation.value = this.options.blurSeparation;

    this.filmicMaterial.uniforms.lensDistortion.value =
      this.options.lensDistortion;
    this.filmicMaterial.uniforms.cubeDistortion.value =
      this.options.cubeDistortion;
    this.filmicMaterial.uniforms.chromaticDispersion.value =
      this.options.chromaticDispersion;
    this.filmicMaterial.uniforms.scale.value = this.options.scale;
    this.filmicMaterial.uniforms.noiseAmount.value = this.options.noiseAmount;
    this.filmicMaterial.uniforms.focusZ.value = this.options.focusZ;
    this.filmicMaterial.uniforms.near.value = this.options.near;
    this.filmicMaterial.uniforms.far.value = this.options.far;
    this.filmicMaterial.uniforms.renderDepth.value = this.options.renderDepth;
  }

  setSize(width: number, height: number) {
    this.blurMaterial.uniforms.width.value = width;
    this.blurMaterial.uniforms.height.value = height;
    this.filmicMaterial.uniforms.width.value = width;
    this.filmicMaterial.uniforms.height.value = height;
    this.depthRenderTarget.setSize(width / 2, height / 2);
    this.blurRenderTarget.setSize(width / 2, height / 2);
  }

  render(
    renderer: WebGLRenderer,
    writeBuffer: WebGLRenderTarget,
    readBuffer: WebGLRenderTarget,
  ) {
    const dest = this.renderToScreen ? null : writeBuffer;

    // Render depth (tDepth)

    this.scene.overrideMaterial = this.depthMaterial;

    renderer.getClearColor(this.oldClearColor);
    const oldClearAlpha = renderer.getClearAlpha();
    const oldAutoClear = renderer.autoClear;
    renderer.autoClear = false;

    renderer.setClearColor(0xffffff);
    renderer.setClearAlpha(1.0);
    renderer.setRenderTarget(this.depthRenderTarget);
    renderer.clear();
    renderer.render(this.scene, this.camera);

    // Render blur (tBlur)

    this.scene.overrideMaterial = null;
    this.blurMaterial.uniforms.tDiffuse.value = readBuffer.texture;

    renderer.setRenderTarget(this.blurRenderTarget);
    renderer.clear();
    this.blurQuad.render(renderer);

    // Render filmic

    this.filmicMaterial.uniforms.tDiffuse.value = readBuffer.texture;
    this.filmicMaterial.uniforms.nearClip.value = this.camera.near;
    this.filmicMaterial.uniforms.farClip.value = this.camera.far;
    this.filmicMaterial.uniforms.frame.value++;

    renderer.setRenderTarget(dest);
    // if (!this.renderToScreen) renderer.clear();
    this.fullScreenQuad.render(renderer);

    renderer.setClearColor(this.oldClearColor);
    renderer.setClearAlpha(oldClearAlpha);
    renderer.autoClear = oldAutoClear;
  }
}

export { FilmicPass, FilmicPassOptions };
