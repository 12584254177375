import { Group, Curve, Vector3, Vector2, Camera } from "three";
import GUI from "lil-gui";
import gsap from "gsap";

import Debugable from "../interfaces/Debugable.js";
import Options from "../interfaces/Options.js";

/**
 * Configuration options for the {@link CameraCrane}.
 */
export type CameraCraneOptions = {
  /**
   * The {@link Camera} to hold in the gimbal.
   */
  camera?: Camera | null;
  /**
   * The path that the camera crane moves along when {@link CameraCrane.move}
   * is invoked.
   */
  path?: Curve<Vector3> | null;
  /**
   * The target that the camera crane will focus on.
   */
  target?: Vector3 | null;
  /**
   * Whether to focus on the target while moving.
   */
  focus?: boolean;
  /**
   * The tween variables for use with {@link gsap} in {@link CameraCrane.move}.
   */
  tweenVars?: gsap.TweenVars;
};

/**
 * Class that represents a camera crane, which contains a gimbal that holds a
 * camera and provides functionalities such as camera shake and moving along
 * a path.
 *
 * @remarks
 *
 * When using the camera crane, to move the camera you will move the
 * {@link CameraCrane.gimbal | gimbal} instead. The
 * {@link Group.position | position} of the camera should be 0 unless for
 * special effects.
 *
 * The supported functionalities of the camera crane are:
 *
 * - Moving along a path using {@link gsap} ({@link CameraCrane.move}).
 * - Focus on a specified target ({@link CameraCrane.focus}).
 * - Camera shake: e.g. as if handheld for cinematic effect
 *   ({@link CameraCrane.shake}).
 */
export default class CameraCrane implements Debugable, Options {
  /**
   * The current configuration of this camera crane. Do not modify directly; see
   * {@link CameraCrane.applyOptions} instead.
   */
  options: CameraCraneOptions;
  /**
   * The
   * {@linkplain https://en.wikipedia.org/wiki/Gimbal#Film_and_video | gimbal}
   * holding the camera, which provides an additional layer of rotation on top
   * of the camera's rotation.
   *
   * @readonly
   */
  readonly gimbal: Group;
  /**
   * Whether or not the camera crane is moving along a curve.
   *
   * @readonly
   */
  moving: boolean;
  /**
   * The progress of moving along a curve as invoked by
   * {@link CameraCrane.move}. 0 is at the start, 1 is at the end.
   *
   * @readonly
   */
  moveProgress: number;
  debugFolder?: GUI;

  private moveTween?: gsap.core.Tween;
  private handheld: THREE.Vector2;
  private oldHandheld: THREE.Vector2;
  private handheldTween?: gsap.core.Tween;

  /**
   * Creates a new camera crane with the specified options.
   *
   * The default values for any omitted options are below:
   * ```ts
   * this.options = {
   *   camera: null,
   *   path: null,
   *   target: null,
   *   focus: true,
   *   tweenVars: {
   *     duration: 1,
   *   }
   * };
   * ```
   */
  constructor(options?: CameraCraneOptions) {
    this.gimbal = new Group();
    this.options = {
      camera: null,
      path: null,
      target: null,
      focus: true,
      tweenVars: {
        duration: 1,
      },
    };

    if (options !== undefined) {
      this.applyOptions(options);
    }

    this.moveProgress = 0;
    this.moving = false;
    this.handheld = new Vector2();
    this.oldHandheld = new Vector2();
  }

  /**
   * Applies the specified configuration options. All objects are passed by
   * reference. Omitted options will keep the same values.
   *
   * @param options The {@link CameraCraneOptions} to use.
   */
  applyOptions(options: CameraCraneOptions) {
    const oldCamera = this.options.camera;

    if (options.camera !== undefined) this.options.camera = options.camera;
    if (options.path !== undefined) this.options.path = options.path;
    if (options.target !== undefined) this.options.target = options.target;
    if (options.focus !== undefined) this.options.focus = options.focus;
    if (options.tweenVars !== undefined)
      this.options.tweenVars = options.tweenVars;

    if (oldCamera != null) {
      this.gimbal.remove(oldCamera);
    }

    if (
      this.options.camera != null &&
      this.options.camera.parent !== this.gimbal
    ) {
      this.gimbal.add(this.options.camera);
    }
  }

  /**
   * Immediately focuses on the specified target, or the target specified in
   * {@link CameraCrane.options | options}. If both are undefined, do nothing.
   *
   * @param target A target to focus on, if it differs from
   *               {@link CameraCrane.options options.target}.
   */
  focus(target?: Vector3) {
    const t = target ?? this.options.target;

    if (t != null) {
      this.gimbal.lookAt(t);
    }
  }

  /**
   * Moves the gimbal along the specified path in
   * {@link CameraCrane.options | options}.
   *
   * @param from      The point on the path to move from. 0 is the start of the
   *                  path, 1 is the end. Note that if
   *                  {@link CameraCrane.moveProgress | moveProgress} is not at
   *                  this point specified by `from`, the gimbal will instantly
   *                  teleport to it.
   * @param to        The point on the path to move to. 0 is the start of the
   *                  path, 1 is the end.
   * @param tweenVars The variables to use for the {@link gsap} animation, such
   *                  as `duration`, `delay`, `ease`, and so on, if different
   *                  from the ones specified in
   *                  {@link CameraCrane.options | options}.
   */
  move(
    from: number = this.moveProgress,
    to: number = 1,
    tweenVars: gsap.TweenVars = this.options.tweenVars!,
  ) {
    if (this.options.path === undefined) {
      return;
    }

    if (this.moveTween !== undefined) {
      this.moveTween.kill();
    }

    this.moveTween = gsap.fromTo(
      this,
      {
        moveProgress: from,
      },
      {
        ...tweenVars,
        moveProgress: to,
        onStart: () => {
          this.moving = true;

          if (tweenVars?.onStart !== undefined) {
            tweenVars?.onStart();
          }
        },
        onUpdate: () => {
          this.updateGimbalPosition();
          if (this.options.focus) {
            this.focus();
          }

          if (tweenVars?.onUpdate !== undefined) {
            tweenVars?.onUpdate();
          }
        },
        onComplete: () => {
          this.moving = false;

          if (tweenVars?.onComplete !== undefined) {
            tweenVars?.onComplete();
          }
        },
      },
    );
  }

  /**
   * Stops moving the gimbal.
   */
  stopMoving() {
    if (this.moveTween !== undefined) {
      this.moveTween.pause();
    }
  }

  /**
   * Begins shaking the gimbal. This method only needs to be invoked once. To
   * stop shaking, use {@link CameraCrane.stopShaking}.
   */
  shake() {
    if (this.handheldTween !== undefined) {
      this.handheldTween.kill();
    }

    this.handheldTween = gsap.to(this.handheld, {
      x: (Math.random() - 0.5) * 0.2,
      y: (Math.random() - 0.5) * 0.2,
      duration: Math.random() * 3 + 1,
      ease: "sine.inOut",
      onUpdate: () => {
        this.gimbal.position.sub(
          new Vector3(this.oldHandheld.x, this.oldHandheld.y, 0),
        );
        this.gimbal.position.add(
          new Vector3(this.handheld.x, this.handheld.y, 0),
        );
        this.oldHandheld.copy(this.handheld);
      },
      onComplete: this.shake.bind(this),
    });
  }

  /**
   * Stops shaking the gimbal.
   */
  stopShaking() {
    if (this.handheldTween !== undefined) {
      this.handheldTween.pause();
    }
  }

  /**
   * Updates the gimbal's position to the
   * {@link CameraCrane.moveProgress | moveProgress}. If `focus` is set in
   * {@link CameraCrane.options | options}, focus on the target.
   */
  private updateGimbalPosition() {
    if (this.options.path == null) {
      return;
    }

    this.gimbal.position
      .copy(this.options.path.getPoint(this.moveProgress))
      .add(new Vector3(this.oldHandheld.x, this.oldHandheld.y, 0));

    if (this.options.focus) {
      this.focus();
    }
  }

  setDebugFolder(debugGui: GUI, name: string) {
    this.debugFolder = debugGui.addFolder(name);

    this.debugFolder
      .add(this, "moveProgress", 0, 1, 0.01)
      .onChange(this.updateGimbalPosition.bind(this))
      .listen();
  }
}
