import * as THREE from "three";

export const galleryCameraCurvePointsPortrait = [
  new THREE.Vector3(0, 1.9212, 5),
  new THREE.Vector3(0, 1.9212, 4.26),
  new THREE.Vector3(-0.31, 1.9212, 3.26),
  new THREE.Vector3(0.59, 1.92, -0.08),
  new THREE.Vector3(-0.41, 1.92, -3.42),
  new THREE.Vector3(0.49, 1.92, -6.42),
  new THREE.Vector3(-0.3, 1.9212, -8.09),
];

export const galleryCameraCurvePointsLandscape = [
  new THREE.Vector3(0, 1.9212, 5),
  new THREE.Vector3(-0.3, 1.9212, -8.09),
];
