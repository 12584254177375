export type PortfolioExperienceHtml = {
  nav: HTMLElement | null;
  homeDiv: HTMLElement | null;
  homeDivSpans: NodeListOf<HTMLElement>;
  aboutDiv: HTMLElement | null;
  aboutDivSpans: NodeListOf<HTMLElement>;
  workDiv: HTMLElement | null;
  workDivSpans: NodeListOf<HTMLElement>;
  homeAnchor?: HTMLElement | null;
  aboutAnchor?: HTMLElement | null;
  workAnchor?: HTMLElement | null;
  creditsAnchor?: HTMLElement | null;
};

const nav = document.querySelector("nav");

const html: PortfolioExperienceHtml = {
  nav,

  // Page div & spans

  homeDiv: document.querySelector(".home"),
  homeDivSpans: document.querySelectorAll(".home span"),

  aboutDiv: document.querySelector(".about"),
  aboutDivSpans: document.querySelectorAll(".about span"),

  workDiv: document.querySelector(".work"),
  workDivSpans: document.querySelectorAll(".work span"),

  // Nav items

  homeAnchor: nav?.querySelector("a:nth-child(1)"),
  aboutAnchor: nav?.querySelector("a:nth-child(2)"),
  workAnchor: nav?.querySelector("a:nth-child(3)"),
  creditsAnchor: nav?.querySelector("a:nth-child(4)"),
};

export default html;
