import { Camera, Matrix3, Mesh, ShaderMaterial, Vector2 } from "three";
import GUI from "lil-gui";
import gsap from "gsap";

import Debugable from "../../interfaces/Debugable.js";
import Mouse from "../../utils/Mouse.js";
import Options from "../../interfaces/Options.js";
import PortfolioExperience from "../../PortfolioExperience.js";
import Update from "../../interfaces/Update.js";
import fragmentShader from "../shaders/monitor.frag.glsl";
import vertexShader from "../shaders/monitor.vert.glsl";

export type MonitorOptions = {
  width?: number;
  height?: number;
  mouseTweenDuration?: number;
  mouseTweenDelay?: number;
  displayTweenDuration?: number;
};

export default class Monitor implements Debugable, Update, Options {
  options: MonitorOptions;

  mesh: Mesh;
  material: ShaderMaterial;

  private readonly mouse: Vector2;
  private uv: Vector2;
  // private mouseTween?: gsap.core.Tween;
  private displayTransitionTween?: gsap.core.Tween;
  private displayTransitionProgress: number;
  private displayTransitionData: Matrix3;

  debugFolder?: GUI;

  constructor(mesh: Mesh, options?: MonitorOptions) {
    this.mesh = mesh;
    this.options = {
      width: 7.56,
      height: 7.68,
      mouseTweenDuration: 1,
      mouseTweenDelay: 0.05,
      displayTweenDuration: 0.6,
    };

    this.mouse = new Vector2();
    this.uv = new Vector2(0.5, 0.5);
    this.displayTransitionProgress = 1;
    // prettier-ignore
    this.displayTransitionData = new Matrix3().set(
      0, 0, 0,
      0, 0, 0,
      0, 0, 0,
    );

    // Material

    this.material = new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        resolution: {
          value: new Vector2(this.options.width, this.options.height),
        },
        mouse: { value: this.mouse },
        displayIndex: {
          value: 0,
        },
        displayTransitionProgress: {
          value: 0,
        },
        displayTransitionData: {
          value: this.displayTransitionData,
        },
      },
      vertexShader,
      fragmentShader,
    });

    this.mesh.material = this.material;
    this.applyOptions(options);
  }

  applyOptions(options?: MonitorOptions) {
    if (options?.width != null) {
      this.options.width = options.width;
    }
    if (options?.height != null) {
      this.options.height = options.height;
    }
    if (options?.mouseTweenDuration != null) {
      this.options.mouseTweenDuration = options.mouseTweenDuration;
    }
    if (options?.mouseTweenDelay != null) {
      this.options.mouseTweenDelay = options.mouseTweenDelay;
    }
    if (options?.displayTweenDuration != null) {
      this.options.displayTweenDuration = options.displayTweenDuration;
    }

    this.material.uniforms.resolution.value.x = this.options.width;
    this.material.uniforms.resolution.value.y = this.options.height;
  }

  display(newIndex: number) {
    // [ o  n2 p3 ]
    // [ n1 p2 n4 ]
    // [ p1 n3 p4 ]
    //
    // [  o n1 p1 n2 p2 n3 p3 n4 p4 ] column-major
    // [ n1 n2 p2 n3 p3 n4 p4 n5 p5 ] letting go of one layer - mapping
    // [  0  1  2  3  4  5  6  7  8 ] index

    const { displayIndex, displayTransitionData } = this.material.uniforms;

    const currentIndex: number = displayIndex.value;
    const data: Matrix3 = displayTransitionData.value;

    if (newIndex === currentIndex) {
      // prettier-ignore
      data.set(
        currentIndex, currentIndex, 0,
        currentIndex, 0, currentIndex,
        0, currentIndex, 0,
      );

      return;
    }

    displayIndex.value = newIndex;
    const onComplete = () => {
      // prettier-ignore
      data.set(
        newIndex, newIndex, 0,
        newIndex, 0, newIndex,
        0, newIndex, 0
      );
    };

    if (data.elements[2] === 0) {
      data.elements[0] = currentIndex;
      data.elements[1] = newIndex;

      this.displayTransitionTween = gsap.fromTo(
        this,
        {
          displayTransitionProgress: 0,
        },
        {
          displayTransitionProgress: 1,
          duration: this.options.displayTweenDuration,
          onUpdate: () => {
            data.elements[2] = this.displayTransitionProgress;
          },
          onComplete,
        },
      );

      return;
    }

    if (data.elements[4] === 0) {
      this.displayTransitionTween!.kill();
      data.elements[3] = newIndex;

      this.displayTransitionTween = gsap.fromTo(
        this,
        {
          displayTransitionProgress: 0,
        },
        {
          displayTransitionProgress: 1,
          duration: this.options.displayTweenDuration,
          onUpdate: () => {
            data.elements[4] = this.displayTransitionProgress;
          },
          onComplete,
        },
      );

      return;
    }

    if (data.elements[6] === 0) {
      this.displayTransitionTween!.kill();
      data.elements[5] = newIndex;

      this.displayTransitionTween = gsap.fromTo(
        this,
        {
          displayTransitionProgress: 0,
        },
        {
          displayTransitionProgress: 1,
          duration: this.options.displayTweenDuration,
          onUpdate: () => {
            data.elements[6] = this.displayTransitionProgress;
          },
          onComplete,
        },
      );

      return;
    }

    if (data.elements[8] === 0) {
      this.displayTransitionTween!.kill();
      data.elements[7] = newIndex;

      this.displayTransitionTween = gsap.fromTo(
        this,
        {
          displayTransitionProgress: 0,
        },
        {
          displayTransitionProgress: 1,
          duration: this.options.displayTweenDuration,
          onUpdate: () => {
            data.elements[8] = this.displayTransitionProgress;
          },
          onComplete,
        },
      );

      return;
    }

    // We ran out of space in our transition data matrix to store one more layer
    // of transition, so we have to let go the first one.

    this.displayTransitionTween!.kill();

    // prettier-ignore
    data.set(
      data.elements[1], data.elements[5], data.elements[8],
      data.elements[3], data.elements[6], newIndex,
      data.elements[4], data.elements[7], 0
    );

    this.displayTransitionTween = gsap.fromTo(
      this,
      {
        displayTransitionProgress: 0,
      },
      {
        displayTransitionProgress: 1,
        duration: this.options.displayTweenDuration,
        onUpdate: () => {
          data.elements[8] = this.displayTransitionProgress;
        },
        onComplete,
      },
    );
  }

  setDebugFolder(debugGui: GUI, name: string) {
    this.debugFolder = debugGui.addFolder(name);

    this.debugFolder
      .add(this.options, "width", 1, 20, 0.01)
      .onChange((width: number) => {
        this.material.uniforms.resolution.value.x = width;
      })
      .listen();
    this.debugFolder
      .add(this.options, "height", 1, 20, 0.01)
      .onChange((height: number) => {
        this.material.uniforms.resolution.value.y = height;
      })
      .listen();
    this.debugFolder
      .add(this.material.uniforms.displayIndex, "value", 0, 3, 1)
      .name("displayIndex")
      .listen();
  }

  update(experience: PortfolioExperience) {
    this.castRay(experience.mouse, experience.activeCamera);
    this.material.uniforms.time.value = experience.time.elapsedMs * 0.001;
  }

  private castRay(mouse: Mouse, camera: Camera) {
    mouse.raycaster!.setFromCamera(mouse.ndc ?? { x: 0, y: 0 }, camera);

    const intersects = mouse.raycaster!.intersectObject(this.mesh);

    if (intersects[0]?.uv != null) {
      if (!this.uv.equals(intersects[0].uv)) {
        this.uv.copy(intersects[0].uv);
        this.tween();
      }
    } else if (this.uv.x !== 0 && this.uv.y !== 0) {
      this.uv.set(0.5, 0.5);
      this.tween();
    }
  }

  private tween() {
    const target = this.uv
      .clone()
      .multiplyScalar(2)
      .subScalar(1) // transform to [-1, 1] space
      .multiply(new Vector2(this.options.width! / this.options.height!, 1)); // adjust to the aspect ratio of the display

    gsap.to(this.material.uniforms.mouse.value, {
      x: target.x,
      y: target.y,
      duration: this.options.mouseTweenDuration,
      delay: this.options.mouseTweenDelay,
    });
  }
}
