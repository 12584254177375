import { Color, Mesh, PlaneGeometry, ShaderMaterial, Vector2 } from "three";
import GUI from "lil-gui";
import gsap from "gsap";

import Debugable from "../interfaces/Debugable.js";
import Options from "../interfaces/Options.js";
import PortfolioExperience from "../PortfolioExperience.js";
import Update from "../interfaces/Update.js";
import fragmentShader from "./shaders/overlay.frag.glsl";
import vertexShader from "./shaders/overlay.vert.glsl";

export type OverlayOptions = {
  zPos: Vector2;
  zWidth: number;
  zTopStrokeThickness: number;
  zBottomStrokeThickness: number;
  zInnerHeight: number;
  zConnectorThickness: number;
  zScale: number;
  zColor: Color;
  backgroundColor: Color;
};

export default class Overlay implements Debugable, Update, Options {
  readonly options: OverlayOptions;
  readonly transitionTimeline: gsap.core.Timeline;
  readonly material: ShaderMaterial;
  readonly mesh: Mesh;

  debugFolder?: GUI;

  constructor(width: number, height: number, options: OverlayOptions) {
    this.options = { ...options };

    this.transitionTimeline = gsap.timeline();

    const geometry = new PlaneGeometry(2, 2);

    this.material = new ShaderMaterial({
      transparent: true,
      uniforms: {
        resolution: {
          value: new Vector2(width, height),
        },
        uvScale: {
          value: new Vector2(
            this.options.zWidth,
            this.options.zWidth /
              (this.options.zTopStrokeThickness +
                this.options.zInnerHeight +
                this.options.zBottomStrokeThickness),
          ),
        },
        uvScaleMultiplier: { value: 2.0 },
        uvDisplacement: { value: new Vector2() },
        uvDisplacementTo: { value: new Vector2() },
        zPos: { value: this.options.zPos },
        zWidth: { value: this.options.zWidth },
        zTopStrokeThickness: { value: this.options.zTopStrokeThickness },
        zBottomStrokeThickness: { value: this.options.zBottomStrokeThickness },
        zInnerHeight: { value: this.options.zInnerHeight },
        zConnectorThickness: { value: this.options.zConnectorThickness },
        zScale: { value: this.options.zScale },
        zAlpha: { value: 1 },
        centerZAlpha: { value: 1 },
        loading: { value: 0 },
        otherZMix: { value: 0 },
        zColor: { value: this.options.zColor },
        backgroundColor: { value: this.options.backgroundColor },
      },
      vertexShader,
      fragmentShader,
    });

    this.mesh = new Mesh(geometry, this.material);
  }

  applyOptions(options: OverlayOptions) {
    const {
      zPos,
      zWidth,
      zTopStrokeThickness,
      zBottomStrokeThickness,
      zInnerHeight,
      zConnectorThickness,
      zScale,
      zColor,
      backgroundColor,
    } = options;

    this.options.zPos.copy(zPos);
    this.options.zWidth = zWidth;
    this.options.zTopStrokeThickness = zTopStrokeThickness;
    this.options.zBottomStrokeThickness = zBottomStrokeThickness;
    this.options.zInnerHeight = zInnerHeight;
    this.options.zConnectorThickness = zConnectorThickness;
    this.options.zScale = zScale;
    this.options.zColor.copy(zColor);
    this.options.backgroundColor.copy(backgroundColor);
  }

  setSize(width: number, height: number) {
    this.material.uniforms.resolution.value.set(width, height);
  }

  setDebugFolder(debugGui: GUI, name: string): void {
    this.debugFolder = debugGui.addFolder(name);

    this.debugFolder.add(this, "transition");

    this.debugFolder
      .add(this.material.uniforms.uvScale.value, "x", 0, 3, 0.01)
      .name("uvScale.x")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.uvScale.value, "y", 0, 3, 0.01)
      .name("uvScale.y")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.uvScaleMultiplier, "value", 0, 3, 0.01)
      .name("uvScaleMultiplier")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.uvDisplacement.value, "x", -5, 5, 0.01)
      .name("uvDisplacement.x")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.uvDisplacement.value, "y", -5, 5, 0.01)
      .name("uvDisplacement.y")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.uvDisplacementTo.value, "x", -5, 5, 1)
      .name("uvDisplacementTo.x")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.uvDisplacementTo.value, "y", -5, 5, 1)
      .name("uvDisplacementTo.y")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.zPos.value, "x", -1, 1, 0.01)
      .name("zPos.x")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.zPos.value, "y", -1, 1, 0.01)
      .name("zPos.y")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.zWidth, "value", 0, 1, 0.01)
      .name("zWidth")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.zTopStrokeThickness, "value", 0, 1, 0.01)
      .name("zTopStrokeThickness");
    this.debugFolder
      .add(this.material.uniforms.zBottomStrokeThickness, "value", 0, 1, 0.01)
      .name("zBottomStrokeThickness");
    this.debugFolder
      .add(this.material.uniforms.zInnerHeight, "value", 0, 1, 0.01)
      .name("zInnerHeight");
    this.debugFolder
      .add(this.material.uniforms.zConnectorThickness, "value", 0, 1, 0.01)
      .name("zConnectorThickness");
    this.debugFolder
      .add(this.material.uniforms.zScale, "value", 0, 5, 0.01)
      .name("zScale")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.zAlpha, "value", 0, 1, 0.01)
      .name("zAlpha")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.centerZAlpha, "value", 0, 1, 0.01)
      .name("centerZAlpha")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.loading, "value", 0, 1, 0.01)
      .name("loading")
      .listen();
    this.debugFolder
      .add(this.material.uniforms.otherZMix, "value", 0, 1, 0.01)
      .name("otherZMix")
      .listen();
    this.debugFolder
      .addColor(this.material.uniforms.zColor, "value")
      .name("zColor");
    this.debugFolder
      .addColor(this.material.uniforms.backgroundColor, "value")
      .name("backgroundColor");
  }

  transition(callback?: () => void): gsap.core.Timeline {
    const {
      uvScaleMultiplier,
      uvDisplacement,
      uvDisplacementTo,
      centerZAlpha,
    } = this.material.uniforms;

    const xOrY = Math.round(Math.random()); // 0 for x, 1 for y
    const direction = Math.round(Math.random()) * 2 - 1;
    const displacement = direction * 4;
    const displacesX = Math.abs(xOrY - 1);
    const displacesY = Math.abs(xOrY);

    uvDisplacementTo.value.set(
      displacement * displacesX,
      displacement * displacesY,
    );

    uvDisplacement.value.x = -0.6 * displacesX * direction;
    uvDisplacement.value.y = -0.6 * displacesY * direction;

    this.transitionTimeline
      .clear()
      .restart() // resets playhead to 0
      .to(
        centerZAlpha,
        {
          value: 1,
          duration: 0.3,
          onComplete: callback,
        },
        "0.2",
      )
      .to(
        uvDisplacement.value,
        {
          x: displacement * displacesX,
          y: displacement * displacesY,
          duration: 3,
          ease: "expo.out",
        },
        0,
      )
      .to(
        uvScaleMultiplier,
        {
          value: 2,
          duration: 1,
          ease: "expo.Out",
        },
        0,
      )
      .to(
        uvScaleMultiplier,
        {
          value: 0,
          duration: 1,
          ease: "expo.in",
        },
        ">",
      )
      .to(
        centerZAlpha,
        {
          value: 0,
          duration: 0.3,
        },
        "<0.4",
      );

    return this.transitionTimeline;
  }

  update(experience: PortfolioExperience) {
    this.mesh.position.copy(experience.cameraCrane.gimbal.position);
  }
}
