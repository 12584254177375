import { WebGLRenderer, WebGLRenderTarget } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";

import Experience from "../interfaces/Experience.js";
import Update from "../interfaces/Update.js";

/**
 * A wrapper class on top of {@link WebGLRenderer} and {@link EffectComposer}.
 */
export default class RenderManager implements Update {
  /**
   * The canvas that this render manager is drawing on.
   *
   * @readonly
   */
  readonly canvas: HTMLCanvasElement;
  /**
   * Whether anti-aliasing is enabled in the {@link WebGLRenderer}.
   *
   * @readonly
   */
  readonly antialias: boolean;
  /**
   * Whether post processing is enabled.
   */
  postProcessing: boolean;

  /**
   * The {@link WebGLRenderer} instance associated with this `RenderManager`.
   *
   * @readonly
   */
  readonly webGlRenderer: WebGLRenderer;
  /**
   * The {@link EffectComposer} instance associated with this `RenderManager`.
   *
   * @readonly
   */
  readonly composer?: EffectComposer;
  /**
   * The render target used by the effect composer when drawing.
   *
   * @readonly
   */
  readonly renderTarget?: WebGLRenderTarget;

  /**
   * Creates a new `RenderManager`.
   *
   * @param canvas The canvas this render manager will draw on.
   */
  constructor(
    canvas: HTMLCanvasElement,
    width: number,
    height: number,
    antialias: boolean = false,
    postProcessing: boolean = false,
  ) {
    this.canvas = canvas;
    this.antialias = antialias;
    this.postProcessing = postProcessing;

    this.webGlRenderer = new WebGLRenderer({
      canvas: this.canvas,
      antialias: this.antialias,
    });
    this.webGlRenderer.setSize(width, height);
    this.webGlRenderer.setPixelRatio(window.devicePixelRatio);

    if (postProcessing) {
      this.postProcessing = true;

      this.renderTarget = new WebGLRenderTarget(800, 600);

      this.composer = new EffectComposer(this.webGlRenderer, this.renderTarget);
      this.composer.setSize(width, height);
      this.composer.setPixelRatio(window.devicePixelRatio);
    }
  }

  setSize(width: number, height: number) {
    this.webGlRenderer.setSize(width, height);
    this.webGlRenderer.setPixelRatio(window.devicePixelRatio);

    if (this.composer != null) {
      this.composer.setSize(width, height);
      this.composer.setPixelRatio(window.devicePixelRatio);
    }
  }

  update(experience: Experience) {
    if (this.composer != null && this.postProcessing) {
      this.composer.render();
    } else {
      this.webGlRenderer.render(
        experience.activeScene,
        experience.activeCamera,
      );
    }
  }
}
