import gsap from "gsap";

import Debugable from "../interfaces/Debugable.js";
import Options from "../interfaces/Options.js";
import GUI from "lil-gui";
import Device from "../utils/Device.js";
import { Camera, Vector3 } from "three";

export type DeviceOrientationControlsOptions = {
  betaThreshold?: number;
  gammaThreshold?: number;
  rotateDuration?: number;
  rotateBackDuration?: number;
  rotateBackTimeout?: number;
  inverse?: boolean;
};

export default class DeviceOrientationControls implements Options, Debugable {
  enabled: boolean;
  camera: Camera;
  device: Device;
  options: DeviceOrientationControlsOptions;

  debugFolder?: GUI;

  private timeout?: NodeJS.Timeout;

  readonly rotation: Vector3 = new Vector3();
  private oldRotation: Vector3 = new Vector3();
  private deltaAccumulator: Vector3 = new Vector3(0, 0, 0);
  private oldOrientation?: Vector3;

  private rotateTween?: gsap.core.Tween;

  constructor(
    camera: THREE.Camera,
    device: Device,
    options?: DeviceOrientationControlsOptions,
  ) {
    this.options = {
      betaThreshold: 1,
      gammaThreshold: 1,
      rotateDuration: 0.8,
      rotateBackDuration: 0.8,
      rotateBackTimeout: 200,
      inverse: false,
    };

    if (options !== undefined) {
      this.applyOptions(options);
    }

    this.enabled = true;
    this.camera = camera;
    this.device = device;

    this.device.addEventListener("orientation", this.update.bind(this));
  }

  applyOptions(options: DeviceOrientationControlsOptions): void {
    if (options.betaThreshold !== undefined) {
      this.options.betaThreshold = options.betaThreshold;
    }
    if (options.gammaThreshold !== undefined) {
      this.options.gammaThreshold = options.gammaThreshold;
    }
    if (options.rotateDuration !== undefined) {
      this.options.rotateDuration = options.rotateDuration;
    }
    if (options.rotateBackDuration !== undefined) {
      this.options.rotateBackDuration = options.rotateBackDuration;
    }
    if (options.rotateBackTimeout !== undefined) {
      this.options.rotateBackTimeout = options.rotateBackTimeout;
    }
    if (options.inverse !== undefined) {
      this.options.inverse = options.inverse;
    }
  }

  setDebugFolder(debugGui: GUI, name: string): void {
    this.debugFolder = debugGui.addFolder(name);

    this.debugFolder
      .add(this.rotation, "x")
      .name("rotationX")
      .disable()
      .listen();
    this.debugFolder
      .add(this.rotation, "y")
      .name("rotationY")
      .disable()
      .listen();
    this.debugFolder
      .add(this.rotation, "z")
      .name("rotationZ")
      .disable()
      .listen();
    this.debugFolder
      .add(this.deltaAccumulator, "x")
      .name("deltaAccumulatorX")
      .disable()
      .listen();
    this.debugFolder
      .add(this.deltaAccumulator, "y")
      .name("deltaAccumulatorY")
      .disable()
      .listen();
    this.debugFolder
      .add(this.deltaAccumulator, "z")
      .name("deltaAccumulatorZ")
      .disable()
      .listen();
  }

  private update(event: Event) {
    const { orientation } = (event as CustomEvent).detail;

    const orientationRadians = new Vector3(
      (orientation.x * Math.PI) / 180,
      (orientation.y * Math.PI) / 180,
      (orientation.z * Math.PI) / 180,
    );

    if (this.oldOrientation === undefined) {
      this.oldOrientation = orientationRadians.clone();
    }

    const delta = orientationRadians.clone().sub(this.oldOrientation);
    this.deltaAccumulator.add(delta);

    const absAcc = new Vector3(
      Math.abs(this.deltaAccumulator.x),
      Math.abs(this.deltaAccumulator.y),
      Math.abs(this.deltaAccumulator.z),
    );

    // All units are in radians from now on

    if (
      absAcc.y > (this.options.betaThreshold! * Math.PI) / 180 &&
      absAcc.z > (this.options.gammaThreshold! * Math.PI) / 180
    ) {
      if (this.rotateTween !== undefined) {
        this.rotateTween.kill();
      }

      const rotateTo = this.rotation.clone();
      this.options.inverse
        ? rotateTo.sub(this.deltaAccumulator)
        : rotateTo.add(this.deltaAccumulator);
      const { y, z } = rotateTo;

      this.rotateTween = gsap.to(this.rotation, {
        y,
        z,
        duration: this.options.rotateDuration,
        onUpdate: () => {
          this.camera.rotateX(-this.oldRotation.y);
          this.camera.rotateY(-this.oldRotation.z);
          this.camera.rotateY(this.rotation.z);
          this.camera.rotateX(this.rotation.y);
          this.oldRotation.copy(this.rotation);
        },
      });

      this.deltaAccumulator.set(0, 0, 0);

      if (this.timeout !== undefined) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.rotateTween!.kill();

        this.rotateTween = gsap.to(this.rotation, {
          y: 0,
          z: 0,
          duration: this.options.rotateBackDuration ?? 0.8,
          onUpdate: () => {
            this.camera.rotateX(-this.oldRotation.y);
            this.camera.rotateY(-this.oldRotation.z);
            this.camera.rotateY(this.rotation.z);
            this.camera.rotateX(this.rotation.y);
            this.oldRotation.copy(this.rotation);
          },
        });
      }, this.options.rotateBackTimeout ?? 200);
    }

    this.oldOrientation.copy(orientationRadians);
  }
}
