import * as THREE from "three";
import Debugable from "../interfaces/Debugable.js";
import GUI from "lil-gui";

export default class CatmullRomCurve3Builder implements Debugable {
  curve: THREE.CatmullRomCurve3;
  helper: THREE.Line;
  resolution: number;
  color: THREE.Color;

  debugFolder?: GUI;

  constructor(color: number, resolution: number, points: THREE.Vector3[]) {
    this.curve = new THREE.CatmullRomCurve3([]);
    this.helper = new THREE.Line(
      undefined,
      new THREE.LineBasicMaterial({ color }),
    );
    this.resolution = resolution;
    this.color = new THREE.Color(color);

    this.helper.name = "CatmullRomCurve3Builder";

    points.forEach((point) => {
      this.curve.points.push(point);
    });
  }

  setDebugFolder(debugGui: GUI, name: string): void {
    this.debugFolder = debugGui.addFolder(name);

    if (this.curve.points.length === 0 || this.curve.points.length === 1) {
      this.addMidpoint();
    }

    this.rebuild();
    this.debugFolder.add(this, "addMidpoint");

    const debugFunctions = {
      copyCurveCode: () => {
        let pointsCode = "[";

        this.curve.points.forEach((point) => {
          pointsCode += `new THREE.Vector3(${point.x}, ${point.y}, ${point.z}), `;
        });

        pointsCode = pointsCode.slice(0, pointsCode.length - 2) + "]";

        const curveCode = `new THREE.CatmullRomCurve3(${pointsCode})`;

        navigator.clipboard.writeText(curveCode);
      },
    };

    this.debugFolder.add(debugFunctions, "copyCurveCode");

    this.addPointDebug(this.curve.points.at(0)!);
    this.addPointDebug(this.curve.points.at(this.curve.points.length - 1)!);

    for (let i = 1; i < this.curve.points.length - 1; i++) {
      this.addPointDebug(this.curve.points.at(i)!);
    }
  }

  addMidpoint() {
    const previousPoint = this.curve.points.at(this.curve.points.length - 2)!;
    const midpoint = new THREE.Vector3().copy(previousPoint);
    this.curve.points.splice(this.curve.points.length - 1, 0, midpoint);
    this.addPointDebug(midpoint);
  }

  addPointDebug(point: THREE.Vector3) {
    if (this.debugFolder == null) {
      console.error("Debug folder not found.");
      return;
    }

    const pointDebugFolder = this.debugFolder
      .addFolder("Point")
      .onChange(this.rebuild.bind(this));

    pointDebugFolder.add(point, "x", -25, 25, 0.01);
    pointDebugFolder.add(point, "y", -25, 25, 0.01);
    pointDebugFolder.add(point, "z", -25, 25, 0.01);
  }

  rebuild() {
    this.helper.geometry.dispose();
    this.helper.geometry = new THREE.BufferGeometry().setFromPoints(
      this.curve.getPoints(this.resolution),
    );
  }
}
