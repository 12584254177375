import GUI from "lil-gui";
import Stats from "stats.js";

/**
 * Class for debugging the experience.
 */
export default class Debug {
  /**
   * The {@link GUI} for holding debug information and controls.
   */
  gui: GUI;
  /**
   * The {@link Stats} for displaying the FPS.
   */
  stats: Stats;

  /**
   * Creates a new debug GUI and stats panel and add them to the DOM.
   *
   * @param guiOptions The options for the GUI. See the
   * {@linkplain https://lil-gui.georgealways.com/#GUI | lil-gui documentation}.
   */
  constructor(guiOptions?: { [key: string]: any }) {
    this.gui = new GUI(guiOptions);
    this.stats = new Stats();
    this.stats.showPanel(0);

    document.body.appendChild(this.stats.dom);
  }
}
