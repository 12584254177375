import PortfolioExperience from "../../PortfolioExperience.js";
import { PortfolioExperienceOptions } from "../options.js";
import { homePageOptions } from "./homePageOptions.js";

export const aboutPageOptions: PortfolioExperienceOptions = {
  debugCamera: {
    fov: 35,
    near: 0.1,
    far: 100,
  },
  firstPersonCamera: {
    fov: 60,
    near: 0.1, // needed for overlay to work
    far: 10,
  },
  cameraCrane: {
    path: null,
  },
  boxBlurPass: {
    size: 3,
    separation: 0.3,
  },
  unrealBloomPass: {
    enabled: false,
  },
  filmicPass: {
    lensDistortion: 0,
    cubeDistortion: 0,
    chromaticDispersion: 0.012,
    scale: 0.98,
    noiseAmount: 0.02,
    near: -16.39,
    far: -30,
    blurSize: 5,
    blurSeparation: 1,
  },
  setup: (experience: PortfolioExperience) => {
    const {
      stage,
      gallery,
      galleryCameraPathPortrait,
      galleryCameraPathLandscape,
      cameraCrane,
      debugCamera,
      orbitControls,
      myselfPainting,
      device: sizes,
    } = experience;

    stage!.model.visible = true;
    gallery!.model.visible = false;
    galleryCameraPathPortrait.helper.visible = false;
    galleryCameraPathLandscape.helper.visible = false;

    // Debug camera

    debugCamera?.position.set(-3, 6.5, -24);
    orbitControls?.target.copy(myselfPainting!.mesh.position);

    const isPortrait = sizes.viewportOrientation === "portrait";

    // Painting

    myselfPainting!.portraitCaptionMesh.visible = isPortrait;
    myselfPainting!.landscapeCaptionMesh.visible = !isPortrait;

    // Camera crane

    const viewpoint = myselfPainting!.getViewpoint(1.8, isPortrait);

    cameraCrane.applyOptions({
      target: myselfPainting!.getCenter(isPortrait),
    });

    cameraCrane.gimbal.position.copy(viewpoint);
    cameraCrane.focus();
    cameraCrane.gimbal.rotation.y = Math.PI;
  },
  update: (_experience: PortfolioExperience) => {
    // nothing to do here
  },
  teardown: (_experience: PortfolioExperience) => {},
};
